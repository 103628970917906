<div
  class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor booking-calendar-main"
  (window:resize)="onResize()"
>
  <div class="row mr-lg-2">
    <div class="row w-full">
      <div
        class="col-lg-3 order-2 order-md-1 order-lg-1"
        *ngIf="calendarConfig"
      >
        <mat-calendar
          [selected]="selectedDate"
          [startAt]="startAt"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [dateFilter]="myDateFilter"
          (selectedChange)="onSelect($event)"
        >
        </mat-calendar>
        <div
          class="appointment-to-confirm kt-portlet"
          data-ktportlet="true"
          id="kt_portlet_tools_1"
        >
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">
                {{ "connect.calendar.appointment_confirm" | translate }}
              </h3>
            </div>
            <div class="kt-portlet__head-toolbar">
              <div class="kt-portlet__head-group">
                <a class="btn btn-sm btn-icon btn-clean btn-icon-md">
                  <i class="far fa-calendar-check"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="kt-portlet__body" kt-hidden-height="259">
            <div
              class="kt-notification-v2"
              *ngIf="toConfirmBookingSlots.length !== 0"
            >
              <ul class="notification-lists-bx">
                <li
                  class="d-flex flex-column m-1 row-eq-height card-deck card bg-white mb-3"
                  *ngFor="let bookingSlot of toConfirmBookingSlots"
                >
                  <div
                    class="notification-custom d-flex flex-column col-12 card-body"
                  >
                    <h1 class="notification-title font-weight-bold card-title">
                      {{ bookingSlot.title }}
                      <small>
                        ({{ bookingSlot.user_age }}
                        {{ "connect.calendar.years" | translate }})</small
                      >
                    </h1>
                    <div class="dropdown-divider mb-2"></div>
                    <h5 class="notification-date font-weight-normal mb-2">
                      {{ bookingSlot.starts_at | customDate }},
                      {{ bookingSlot.starts_at | customTime }}
                    </h5>
                    <a
                      class="mb-2 action-btn d-flex align-self-start"
                      href="mailto:{{ bookingSlot.user_email }}"
                      ><span class="notification-email font-weight-normal">{{
                        bookingSlot.user_email
                      }}</span></a
                    >
                    <a
                      class="mb-2"
                      href="tel:{{ bookingSlot.user_phone_number }}"
                      ><span class="notification-phone font-weight-normal">{{
                        bookingSlot.user_phone_number
                      }}</span></a
                    >
                    <p class="notification-request-txt mb-2">
                      {{ "connect.calendar.requested" | translate }}
                      {{
                        bookingSlot.created_at | timeAgo : currentDoctor.locale
                      }}
                    </p>
                    <div class="d-flex align-items-center row col-12">
                      <div
                        class="badge badge-success badge-pill font-weight-bold m-1 col-3"
                        *ngIf="bookingSlot.type === bookingTypes.OFFICE"
                      >
                        {{
                          "connect.appointment_table." + bookingTypes.OFFICE
                            | translate
                        }}
                      </div>
                      <div
                        class="badge badge-primary badge-pill font-weight-bold m-1 col-3"
                        *ngIf="bookingSlot.department"
                      >
                        {{bookingSlot.department?.name}}
                      </div>
                      <div
                        class="badge badge-info badge-pill font-weight-bold m-1 col-3"
                        *ngIf="bookingSlot.type === bookingTypes.HOME"
                      >
                        {{
                          "connect.appointment_table." + bookingTypes.HOME
                            | translate
                        }}
                      </div>
                      <div class="col-3 p-0" *ngIf="bookingSlot">
                        <mat-chip-list
                          class="mat-chip-list-stacked"
                          *ngFor="let type of doctorBookingTypes"
                        >
                          <mat-chip
                            selected="true"
                            class="m-1 p-0"
                            *ngIf="type.id === bookingSlot.booking_slot_type_id"
                            [style.background-color]="type.color"
                          >
                            {{ type.name | titlecase }}
                          </mat-chip>
                        </mat-chip-list>
                      </div>
                      <div
                        class="badge badge-warning badge-pill font-weight-bold m-1 col"
                        *ngIf="
                          bookingSlot.metadata &&
                          bookingSlot.metadata.confirmed_bookings_count === 0
                        "
                      >
                        {{
                          "connect.calendar.first_time_appointment" | translate
                        }}
                      </div>
                      <div
                        class="badge badge-info badge-pill m-1 col"
                        *ngIf="
                          bookingSlot.metadata &&
                          bookingSlot.metadata.confirmed_bookings_count > 0
                        "
                      >
                        {{
                          "connect.calendar.total_number_of_appointments"
                            | translate
                        }}
                        : {{ bookingSlot.metadata.confirmed_bookings_count }}
                      </div>
                    </div>
                  </div>
                  <div class="notification-icons m-auto d-flex flex-row">
                    <a
                      class="btn confirm-new-btn btn-icon confirm-btn m-2 p-3 cursor-pointer"
                      (click)="confirmBookingSlot(bookingSlot)"
                    >
                      <i class="fa fa-check"></i>
                    </a>
                    <a
                      class="btn delete-btn btn-icon cancel-btn m-2 p-3 cursor-pointer"
                      (click)="cancelBookingSlot(bookingSlot)"
                    >
                      <i class="fa fa-times"></i>
                    </a>
                    <a
                      class="btn edit-btn btn-icon m-2 p-3 cursor-pointer"
                      (click)="openBookingModal(bookingSlot)"
                    >
                      <i class="fas fa-edit"></i>
                    </a>
                    <a
                      class="btn confirm-new-btn m-2 p-3 btn-icon confirm-btn cursor-pointer"
                      href="tel:{{ bookingSlot.user_phone_number }}"
                    >
                      <i class="fa fa-phone"></i>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="kt-notification-v2"
              *ngIf="toConfirmBookingSlots.length === 0"
            >
              <p>{{ "connect.calendar.appointment_request" | translate }}</p>
            </div>
          </div>
        </div>
        <div
          [ngClass]="isPortletCollapse === true ? 'kt-portlet--collapse' : ''"
          class="appointment-categories kt-portlet"
        >
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">
                {{ "connect.calendar.appointment_categories" | translate }}
              </h3>
            </div>
            <div class="row">
              <div class="kt-portlet__head-toolbar">
                <div class="kt-portlet__head-group">
                  <a
                    class="btn btn-success btn-rounded pointer p-2 m-1"
                    href="/booking-availabilities/types"
                  >
                    {{
                      "connect.calendar.edit_appointment_categories" | translate
                    }}
                    <i class="fa fa-edit"></i>
                  </a>
                </div>
              </div>
              <div class="kt-portlet__head-toolbar">
                <div class="kt-portlet__head-group">
                  <a
                    (click)="togglePortlet('up')"
                    class="btn btn-sm btn-icon btn-clean btn-icon-md pointer"
                    *ngIf="arrowDirection"
                  >
                    <i class="fas fa-angle-down"></i>
                  </a>
                  <a
                    (click)="togglePortlet('down')"
                    class="btn btn-sm btn-icon btn-clean btn-icon-md pointer"
                    *ngIf="!arrowDirection"
                  >
                    <i class="fas fa-angle-up"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="kt-portlet__body mt-3" *ngIf="!isPortletCollapse">
            <mat-chip-list class="mat-chip-list-stacked">
              <mat-chip
                selected="true"
                *ngFor="let type of doctorBookingTypes"
                [style.background-color]="type.color"
              >
                {{ type.name | titlecase }}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <div
          [ngClass]="isPortletCollapse === true ? 'kt-portlet--collapse' : ''"
          class="appointment-categories kt-portlet"
        >
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">
                {{ "connect.calendar.settings" | translate }}
              </h3>
            </div>
          </div>
          <div class="kt-portlet__body mt-2">
            <div class="mt-2 settings-left">
              <div class="row">
                <div class="col-7">
                  <span class="zoom-lable p-2">{{
                    "connect.calendar.intervals" | translate
                  }}</span>
                </div>
                <div class="col-5">
                  <div class="selection-row">
                    <mat-select
                      class="full-width"
                      placeholder="{{
                        'connect.calendar.select_option' | translate
                      }}"
                      (selectionChange)="changeSlotDuration($event.value)"
                    >
                      <mat-option
                        *ngFor="let interval of timeIntervals"
                        value="{{ interval.value }}"
                        >{{ interval.viewValue }}</mat-option
                      >
                    </mat-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3 settings-left">
              <span class="zoom-lable p-2">{{
                "connect.calendar.set_time" | translate
              }}</span>
              <div class="time-calendar-content mt-3">
                <div class="new-div">
                  <div class="timepickerbox smallinput">
                    <span class="zoom-lable p-2">{{
                      "connect.calendar.start_time" | translate
                    }}</span>
                    <select class="form-control" [(ngModel)]="minTime">
                      <option
                        *ngFor="let hour of startTimeHours"
                        [selected]="minTime == hour"
                      >
                        {{ hour }}
                      </option>
                    </select>
                  </div>
                  <div class="seprator-line">-</div>
                  <div class="timepickerbox smallinput">
                    <span class="zoom-lable p-2">{{
                      "connect.calendar.end_time" | translate
                    }}</span>
                    <select class="form-control" [(ngModel)]="maxTime">
                      <option
                        [value]="hour"
                        *ngFor="let hour of endTimeHours"
                        [selected]="maxTime == hour"
                      >
                        {{ hour }}
                      </option>
                      <option [value]="24">00</option>
                    </select>
                  </div>
                </div>
                <div>
                  <button class="btn add-btn mt-2" (click)="updateSlotTimes()">
                    {{ "connect.calendar.save" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 order-1 order-md-2 order-ld-2">
        <div
          class="kt-portlet booking-calendar desktop-calendar"
          id="kt_portlet"
        >
          <div class="kt-portlet__body" *ngIf="calendarConfig">
            <full-calendar
              #calendar
              schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
              [options]="calendarConfig"
            >
            </full-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
