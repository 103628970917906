import { NgModule, inject } from "@angular/core";
import { CanActivateFn, RouterModule, Routes } from "@angular/router";
import { AppComponent } from "./app.component";

import { AppointmentsTableComponent } from "./components/appointments-table/appointments-table.component";
import { DoctorCommentsComponent } from "./components/doctor-comments/doctor-comments.component";
import { SmsDetailsComponent } from "./components/sms-details/sms-details.component";
import { DoctorSettingsComponent } from "./components/doctor-settings/doctor-settings.component";
import { PrescriptionTableComponent } from "./components/prescription-table/prescription-table.component";
import { TemplateComponent } from "./components/template/template.component";
import { BookingAvailabilityComponent } from "./components/booking-availability/booking-availability.component";
import { PatientLabelComponent } from "./components/patient-label/patient-label.component";
import { DoctorAvailabilitiesNewComponent } from "./components/doctor-availabilities-new/doctor-availabilities-new.component";
import { DoctorPrescriptionHeaderComponent } from "./components/prescription-table/doctor-prescription-header/doctor-prescription-header.component";
import { MedicalRecordPrintComponent } from "./components/medical-record-print/medical-record-print.component";
import { EditProfileComponent } from "./components/edit-profile/edit-profile.component";
import { InvoiceRecordTableComponent } from "./components/invoices/invoice-record-table/invoice-record-table.component";
import { InvoiceDetailsComponent } from "./components/invoices/invoice-details/invoice-details.component";
import { InvoiceVideoComponent } from "./components/invoices/invoice-video/invoice-video.component";
import { ActsComponent } from "./components/acts/acts.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { MedicalRecordsComponent } from "./components/medical-records/medical-records.component";
import { PatientDetailsComponent } from "./components/medical-records/patient-details/patient-details.component";
import { CommentDetailsComponent } from "./components/comments/comment-details/comment-details.component";
import { PrescriptionDetailsComponent } from "./views/prescriptions/prescription-details/prescription-details.component";
import { StatisticsComponent } from "./components/statistics/statistics.component";
import { InboxComponent } from "./components/inbox/inbox.component";
import { CalendarComponent } from "./components/calendar/calendar.component";
import { EmailComponent } from "./components/email/email.component";
import { PartnersComponent } from "./components/partners/partners.component";
import { AvailabilitiesEditComponent } from "./components/availabilities-edit/availabilities-edit.component";
import { AvailabilitiesNewPlannerComponent } from "./components/availabilities-new-planner/availabilities-new-planner.component";
import { AttachmentSharingsListComponent } from "./components/attachment-sharings-list/attachment-sharings-list.component";
import { AssistantsComponent } from "./components/assistants/assistants.component";
import { MassSmsComponent } from "./components/mass-sms/mass-sms.component";
import { PaymentSmsComponent } from "./components/payment-sms/payment-sms.component";
import { AuthGuardService as AuthGuard } from "./services/auth-guard.service";
import preventAdminMiddleware from "./middlewares/prevent-admin";

// import { PaymentComponent } from './components/payment/payment.component'
import { Router } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    component: AppComponent,
    children: [
      { path: "", component: DashboardComponent },
      { path: "medical-records", component: MedicalRecordsComponent , canActivate: [preventAdminMiddleware] },
      { path: "templates", component: TemplateComponent },
      { path: "medical-records/:id", component: PatientDetailsComponent, canActivate: [preventAdminMiddleware]  },
      { path: "medical-note", component: MedicalRecordPrintComponent, canActivate: [preventAdminMiddleware] },
      { path: "comments/:id", component: CommentDetailsComponent },
      {
        path: "booking-availabilities/types",
        component: BookingAvailabilityComponent,
      },
      { path: "profile/edit", component: EditProfileComponent },
      {
        path: "booking-availabilities/new",
        component: DoctorAvailabilitiesNewComponent,
      },
      {
        path: "booking-availabilities/edit",
        component: AvailabilitiesEditComponent,
      },
      {
        path: "booking-availabilities/new-planner",
        component: AvailabilitiesNewPlannerComponent,
      },
      { path: "patient-labels", component: PatientLabelComponent },
      { path: "statistics", component: StatisticsComponent },
      { path: "appointments-list", component: AppointmentsTableComponent },
      { path: "inbox", component: InboxComponent },
      { path: "comments", component: DoctorCommentsComponent },
      { path: "sms", component: SmsDetailsComponent },
      {
        path: "prescriptions/header",
        component: DoctorPrescriptionHeaderComponent,
      },
      { path: "settings", component: DoctorSettingsComponent },
      { path: "email", component: EmailComponent },
      { path: "calendar", component: CalendarComponent },
      { path: "prescriptions", component: PrescriptionTableComponent },
      { path: "prescriptions/:id", component: PrescriptionDetailsComponent },
      { path: "acts", component: ActsComponent },
      { path: "invoices", component: InvoiceRecordTableComponent },
      { path: "invoices/video", component: InvoiceVideoComponent },
      { path: "invoices/:id", component: InvoiceDetailsComponent },
      { path: "assistants", component: AssistantsComponent },
      { path: "docshare", component: AttachmentSharingsListComponent },
      { path: "partners", component: PartnersComponent },
      { path: "mass-sms", component: MassSmsComponent },
      { path: "payment-sms", component: PaymentSmsComponent },
    ],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  isUserLoggedIn: boolean = false;
  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
      this.router.navigate([""]);
    };
  }
}
