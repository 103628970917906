import {
  Component,
  Input,
  ViewChild,
  HostListener,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { HttpWrapper } from "src/app/services/http-wrapper";
import { AsideComponent } from "src/app/components/aside/aside.component";
import { HeaderSearchFieldComponent } from "src/app/components/header/header-search-field/header-search-field.component";
import { DoctorService } from "src/app/services/doctor.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { PlanType } from "src/app/enums/planType.enum";
import { Doctor } from "src/app/models/doctor";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  @Input() currentDoctor: Doctor;
  isToggled: boolean = true;
  addHeaderToggleClass: boolean = false;
  addClass: boolean = false;
  hasConnectPlusAccess: boolean = false;
  disconnectLink: string = environment.doctor_api_url +'/logout';

  @ViewChild(AsideComponent, { static: true }) AsideComponent: AsideComponent;
  @ViewChild(HeaderSearchFieldComponent, { static: true })
  HeaderSearchComponent: HeaderSearchFieldComponent;
  helpVideosLink: string = "https://dabadoc.wistia.com/projects/oeyftlzie7";
  menuItems: any[] = [
    { name: "folder", icon: "folder", link: "medical-records" },
    { name: "agenda", icon: "view_agenda", link: "calendar" },
    { name: "messaging", icon: "messages", link: environment.main_origin + "messages" },
    { name: "inbox", icon: "messages", link: "docshare" },
  ];
  //if prod

  deferredPrompt: any;
  isPwa: boolean = false;

  // We use {{isMobile}} it to check if the user is no mobile or not: Default (False)
  isMobile: boolean = false;

  constructor(
    private http: HttpWrapper,
    private router: Router,
    private translate: TranslateService,
    private doctorService: DoctorService
  ) {
    //Check the device if Mobile then show the calendar by day, otherwise show it by week.
    this.checkDevice();
  }
  ngOnInit() {
    window.addEventListener("beforeinstallprompt", (event) => {
      this.isPwa = true;
      event.preventDefault();
      this.deferredPrompt = event;
    });

    this.doctorService.currentDoctor$.subscribe((currentDoctor) => {
      if (currentDoctor) {
        if (currentDoctor.plan_type == PlanType.CONNECT_PLUS) {
          this.hasConnectPlusAccess = true;
        }
      }
    });
  }
  showWarningMessage() {
    Swal.fire({
      title: this.translate.instant("connect.header.access_denied"),
      text: this.translate.instant("connect.header.upgrade_message"),
      confirmButtonText:
        '<a style="color:#fff;" href="/payment">' +
        this.translate.instant("connect.header.confirm") +
        "</a>",
      cancelButtonText: this.translate.instant("connect.header.cancel"),
      showCancelButton: true,
      type: "info",
    });
  }

  logout() {
    this.doctorService.delete().subscribe((data: any) => {
      localStorage.clear();
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
      if(data.success) return window.location.href = environment.base_url;
    });
  }

  addToHomeScreen() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult) => {
        this.deferredPrompt = null;
      });
    }
  }

  changeSidebar() {
    this.AsideComponent.changeSidebarClass(this.isToggled);
  }

  changeHeader() {
    this.addHeaderToggleClass = !this.addHeaderToggleClass;
  }

  toggleHeaderSearch() {
    this.HeaderSearchComponent.changeSearchClass();
  }

  toggleContent() {
    this.isToggled = !this.isToggled;
  }
  private checkDevice() {
    //Check the device if Mobile then show the calendar by day, otherwise show it by week.
    if (window.innerWidth <= 1024) {
      this.isMobile = true;
    } else this.isMobile = false;
  }
  onResize() {
    this.checkDevice();
  }
}
