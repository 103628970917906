import Swal, { SweetAlertResult } from 'sweetalert2'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SwalType } from '../enums/swalType.enum';


@Injectable(
  {
    providedIn: 'root'
  }
)

export class AlertService {
  constructor(
    private translate: TranslateService
  ) {} 

  showAlert(title: string, message: string = '', type: SwalType = SwalType.Success): Promise<SweetAlertResult>
  {
    return Swal.fire({
      title,
      text: message,
      type: type,
      showCancelButton: false,
      heightAuto: false,
    });
  }

  showConfirmation(title: string, text: string, yes: string = "Yes", no : string  = "No", type: SwalType = SwalType.Success, confirmColor :string = "#3085d6", cancelColor :string = "#d33"): Promise<SweetAlertResult>
  {
    return Swal.fire({
      title,
      text,
      type: type,
      showCancelButton: true,
      confirmButtonText: yes,
      cancelButtonText: no,
      confirmButtonColor: confirmColor,
      cancelButtonColor: cancelColor,
      heightAuto: false,
    });
  }

  notAllowedMedicalRecord() : Promise<SweetAlertResult>
  {
    const title = this.translate.instant('connect.header.access_denied');
    const text = this.translate.instant('connect.header.upgrade_message');
    const confirmButtonText = `<a style="color:#fff;" href="/connect/payment">${this.translate.instant('connect.header.confirm')}</a>`;
    const cancelButtonText = this.translate.instant('connect.header.cancel');

    return this.showConfirmation(title, text, confirmButtonText, cancelButtonText, SwalType.Info);
  }

}