import { Component, ViewChild, OnInit, ViewEncapsulation } from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { BookingSlotsService } from "src/app/services/booking-slots.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { BookingSlot } from "src/app/models/booking-slot";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { DoctorService } from "src/app/services/doctor.service";
import { PatientLabel } from "src/app/models/patient-label";
import { Doctor } from "src/app/models/doctor";
import { SlotType } from "src/app/enums/SlotType.enum";
import { Router } from "@angular/router";
import { BookingHelper } from "src/app/helpers/booking_helper";
import { WillBeLateDialogComponent } from "../will-be-late-dialog/will-be-late-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { environment as appConfig } from "../../../environments/environment";
import { GlobalService } from "src/app/services/global.service";
import { StateType } from "src/app/enums/stateType.enum";
import { BookingType } from "src/app/enums/bookingType.enum";
import { BookingDialogComponent } from "src/app/components/booking-dialog/booking-dialog.component";
import { PlanType } from "src/app/enums/planType.enum";

@Component({
  selector: "appointments-table",
  templateUrl: "./appointments-table.component.html",
  styleUrls: ["./appointment-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('1ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AppointmentsTableComponent implements OnInit {
  displayedColumns = ["houraire", "name", "type", "statut", "actions"];
  dataSource;
  bookingData: BookingSlot[] = [];
  _alldata: any[];
  _newData: any[];
  nextDisable: boolean = false;
  previousDisable: boolean = false;
  currentDoctor: Doctor;
  rowId: string;
  appointmentTypes = SlotType;
  showSearchBar: boolean = false;
  appointmentStates = StateType;
  bookingTypes = BookingType;

  hasConnectPlusAccess: boolean = false;

  appointment_form = new UntypedFormGroup({
    appointment_from: new UntypedFormControl(""),
    appointment_to: new UntypedFormControl(""),
    patient_name: new UntypedFormControl(""),
    type: new UntypedFormControl(""),
    status: new UntypedFormControl(""),
  });

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  isIconToggled: boolean = false;
  types = [
    { value: "office", viewValue: "office" },
    { value: "video", viewValue: "video" },
    { value: "home", viewValue: "home" },
  ];
  appointment_status = [
    "confirmed_user",
    "confirmed_doctor",
    "confirmed_assistant",
    "canceled_user",
    "canceled_doctor",
    "canceled_auto",
    "canceled_assistant",
    "awaiting_payment",
  ];
  listParams = {
    pageNumber: 1,
  };

  confirmed_status = [
    "confirmed_user",
    "confirmed_doctor",
    "confirmed_assistant",
  ];

  constructor(
    private dialog: MatDialog,
    private snackbar: SnackBarService,
    private datePipe: DatePipe,
    private router: Router,
    private translate: TranslateService,
    private bookingSlotsService: BookingSlotsService,
    private errorHandler: ErrorsHandler,
    private doctorService: DoctorService,
    private bookingHelper: BookingHelper,
    private globalService: GlobalService,
    private bookingSlots: BookingSlotsService
  ) {}

  resetData() {
    this.appointment_form.setValue({
      appointment_from: "",
      appointment_to: "",
      type: "",
      status: "",
      patient_name: "",
    });
    this.getDoctorBookingSlots((this.listParams.pageNumber = 1));
  }

  toggleBar() {
    this.showSearchBar = !this.showSearchBar;
  }

  private openBookingSlotModal(data = {}) {
    this.dialog
      .open(BookingDialogComponent, {
        width: "515px",
        data: { bookingSlot: data },
        panelClass: "calendar-dailog",
      })
      .afterClosed()
      .subscribe(() => {
        this.getDoctorBookingSlots(this.listParams.pageNumber);
      });
  }

  private openBookingModal(bookingSlot) {
    this.dialog
      .open(WillBeLateDialogComponent, {
        width: "450px",
        data: bookingSlot,
      })
      .afterClosed()
      .subscribe(() => {});
  }

  getDoctorBookingSlots(params) {
    this.listParams.pageNumber == 1
      ? (this.previousDisable = true)
      : (this.previousDisable = false);
    this.globalService.showSpinner();
    const initialFilter =
      "page=" +
      params +
      "&slot_type=user,manual&type=video,office,home&order_by=starts_at&order=desc";
    this.bookingSlotsService.get(initialFilter).subscribe(
      (data: BookingSlot[]) => {
        data.length < 100
          ? (this.nextDisable = true)
          : (this.nextDisable = false);
        data.filter((element) => {
          this.getPatientLabels(element);
        });
        this.globalService.hideSpinner();
        this._alldata = this.addGroups(data);
        this.dataSource = new MatTableDataSource(this._alldata);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.bookingData.push(...data);
      },
      (error) => this.snackbar.open(error.message)
    );
  }

  checkStatus(status: string): string {
    return this.confirmed_status.includes(status)
      ? "fa fa-check"
      : "fa fa-times";
  }

  showStatus(status: string) {
    Swal.fire({
      title: this.translate.instant(
        "connect.appointment_table.appointment_form.appointment_status." +
          status
      ),
    });
  }

  getPatientLabels(patient) {
    if (patient.patient_labels && this.currentDoctor) {
      patient.patient_labels = this.currentDoctor.patient_labels.filter(
        ({ id }) => patient.patient_labels.includes(id)
      );
    }
  }

  getDoctorInfo() {
    this.doctorService.currentDoctor$.subscribe((currentDoctor) => {
      if (currentDoctor) {
        this.currentDoctor = currentDoctor;
      }
    });
  }

  paginatorBookingSlots(key: string) {
    key == "previous"
      ? this.listParams.pageNumber--
      : this.listParams.pageNumber++;
    if (
      !!this.appointment_form.get("appointment_from").value ||
      !!this.appointment_form.get("appointment_to").value ||
      !!this.appointment_form.get("patient_name").value ||
      !!this.appointment_form.get("type").value ||
      !!this.appointment_form.get("status").value
    ) {
      this.filterBookings(this.listParams.pageNumber);
    } else {
      this.getDoctorBookingSlots(this.listParams.pageNumber);
    }
  }

  addGroups(data: any[]): any[] {
    var _newData = [];
    var groupname = "";
    data.map((row, ii) => {
      let starts_at = row.starts_at.split("T")[0];
      if (groupname != starts_at) {
        _newData.push({ starts_at: starts_at, isGroup: true });
        groupname = starts_at;
      }
      _newData.push(row);
    });
    return _newData;
  }

  isGroup(index, item): boolean {
    return item.isGroup;
  }

  filterBookings(page_index) {
    if (
      this.appointment_form.value.appointment_from ||
      this.appointment_form.value.appointment_to ||
      this.appointment_form.value.patient_name ||
      this.appointment_form.value.type ||
      this.appointment_form.value.status
    ) {
      this.listParams.pageNumber == 1
        ? (this.previousDisable = true)
        : (this.previousDisable = false);
      this.globalService.showSpinner();
      this.appointment_form.value.appointment_from = this.datePipe.transform(
        this.appointment_form.value.appointment_from,
        "yyyy-MM-dd"
      );
      this.appointment_form.value.appointment_to = this.datePipe.transform(
        this.appointment_form.value.appointment_to,
        "yyyy-MM-dd"
      );
      const filter =
        "page=" +
        page_index +
        "&start=" +
        this.appointment_form.value.appointment_from +
        "&end=" +
        this.appointment_form.value.appointment_to +
        "&name=" +
        this.appointment_form.value.patient_name +
        "&type=" +
        this.appointment_form.value.type +
        "&state=" +
        this.appointment_form.value.status;
      this.bookingSlotsService.get(filter).subscribe(
        (data: BookingSlot[]) => {
          data.length < 100
            ? (this.nextDisable = true)
            : (this.nextDisable = false);
          data.filter((element) => {
            this.getPatientLabels(element);
          });
          this.globalService.hideSpinner();
          this._alldata = this.addGroups(data);
          this.dataSource = new MatTableDataSource(this._alldata);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.bookingData.push(...data);
        },
        (error) => this.snackbar.open(error.message)
      );
    }
  }

  doFilter(query: string) {
    this.dataSource.filter = query.trim().toLowerCase();
  }

  confirmBookingSlot(bookingSlot) {
    const text = this.bookingHelper.getCancelMessage(bookingSlot);
    Swal.fire({
      title: this.translate.instant("connect.globals.confirmation_box_title"),
      text: text,
      type: this.translate.instant("connect.globals.confirmation_box_type"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#808285",
      confirmButtonText: this.translate.instant(
        "connect.globals.booking_confirm"
      ),
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          this.translate.instant(
            "connect.appointment_table.booking_slot_confirmed"
          )
        );
        return this.bookingSlotsService.confirm(bookingSlot.id).subscribe(
          (data) => {
            this.getDoctorBookingSlots((this.listParams.pageNumber = 1));
          },
          (error) =>
            this.errorHandler.handleError(
              this.translate.instant("connect.globals.error_occured_message")
            )
        );
      }
    });
  }

  cancelBookingSlot(bookingSlot) {
    const text = this.bookingHelper.getCancelMessage(bookingSlot);
    Swal.fire({
      title: this.translate.instant("connect.globals.confirmation_box_title"),
      text: text,
      type: this.translate.instant("connect.globals.confirmation_box_type"),
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808285",
      confirmButtonText: this.translate.instant(
        "connect.globals.cancel_booking"
      ),
      cancelButtonText: this.translate.instant("connect.globals.cancel"),
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          this.translate.instant(
            "connect.appointment_table.booking_slot_cancelled"
          )
        );
        return this.bookingSlotsService.cancel(bookingSlot.id).subscribe(
          (data) => {
            this.getDoctorBookingSlots((this.listParams.pageNumber = 1));
          },
          (error) =>
            this.errorHandler.handleError(
              this.translate.instant("connect.globals.error_occured_message")
            )
        );
      }
    });
  }

  didntShowUp(bookingSlotId) {
    Swal.fire({
      title: this.translate.instant("connect.globals.patient_absent"),
      text: this.translate.instant("connect.globals.confirmation_box_text"),
      type: this.translate.instant("connect.globals.confirmation_box_type"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#808285",
      confirmButtonText: this.translate.instant(
        "connect.appointment_table.continue"
      ),
      cancelButtonText: this.translate.instant("connect.globals.cancel"),
    }).then((result) => {
      if (result.value) {
        Swal.fire(this.translate.instant("connect.globals.not_show_up"));
        return this.bookingSlots.didntShowUp(bookingSlotId, true).subscribe(
          (data) => {
            this.getDoctorBookingSlots((this.listParams.pageNumber = 1));
          },
          (error) =>
            this.errorHandler.handleError(
              this.translate.instant("connect.globals.try_again_message")
            )
        );
      }
    });
  }

  toggleIcon(row) {
    this.rowId = row.id;
    return (this.isIconToggled = !this.isIconToggled);
  }

  ngOnInit() {
    this.getDoctorInfo();
    this.globalService.showSpinner();
    this.getDoctorBookingSlots((this.listParams.pageNumber = 1));

    this.doctorService.currentDoctor$.subscribe((currentDoctor) => {
      if (currentDoctor) {
        if (currentDoctor.plan_type == PlanType.CONNECT_PLUS) {
          this.hasConnectPlusAccess = true;
        }
      }
    });
  }

  isOnline(type) {
    return type === "user";
  }

  isHome(type: string): boolean {
    return type === "home";
  }

  canceled(state) {
    return (
      state === "canceled_user" ||
      state === "canceled_doctor" ||
      state === "canceled_assistant"
    );
  }

  confirmedBooking(state) {
    return (
      this.isUser(state) || (this.canceled(state) && this.canceledUser(state))
    );
  }

  canceledUser(state) {
    return !(state === "canceled_user");
  }

  isUser(state) {
    return (
      this.isConfirmedByTheUser(state) ||
      (this.canceled(state) && !this.isCanceledByTheUser(state))
    );
  }

  isCanceledByTheUser(state) {
    return state === "canceled_user";
  }

  isConfirmedByTheUser(state) {
    return state === "confirmed_user";
  }

  futureDate(starts_at) {
    return new Date(starts_at) > new Date();
  }

  confirmedDoctorAssistant(state) {
    return state === "confirmed_doctor" || state === "confirmed_assistant";
  }

  selectedDate(ends_at) {
    var endDate = new Date(ends_at);
    endDate.setDate(endDate.getDate() + 3);
    return endDate > new Date();
  }

  pastDate(ends_at) {
    return new Date(ends_at) < new Date();
  }

  hoursDifference(starts_at): boolean {
    starts_at = this.datePipe.transform(starts_at, "EEEE d MMMM y h:mm a");
    const currentDate = this.datePipe.transform(
      new Date(),
      "EEEE d MMMM y h:mm a"
    );
    const diffInMs = Date.parse(starts_at) - Date.parse(currentDate);
    const diffInHours = diffInMs / 1000 / 60 / 60;
    if (diffInHours > 18) {
      return true;
    }
    return false;
  }

  navigateMedicalRecord(MedicalRecordID) {
    if (appConfig.is_doctor) {
      return this.router.navigate(
        ["/medical-records/" + MedicalRecordID],
        { state: { data: "prescription" } }
      );
    }
  }
}
