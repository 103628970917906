import {
  Component,
  AfterViewInit,
  OnInit,
  AfterContentChecked,
} from "@angular/core";
import { DoctorService } from "./services/doctor.service";
import { Doctor } from "./models/doctor";
import { ErrorsHandler } from "./services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription } from "rxjs";
import { GlobalService } from "./services/global.service";
import { tap } from "rxjs/operators";
import { BookingSlotsService } from "./services/booking-slots.service";
import { NotificationServices } from "./services/notification.service";
import { SwPush } from "@angular/service-worker";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment as appConfig } from "../environments/environment";
import { AuthService } from "./services/auth.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { BookingDialogComponent } from "./components/booking-dialog/booking-dialog.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterContentChecked {
  isLoading: boolean = false;
  isDialogOpen: boolean=false;
  dialogRefSubscription: Subscription;
  icondAddColor:string="bg-add-primary"
  currentDoctor: Doctor;
  currentDoctor$: Observable<Doctor>;
  isHeaderShow$: Observable<boolean>;
  isSpinnerShow$: Observable<boolean>;
  constructor(
    private translate: TranslateService,
    private doctorService: DoctorService,
    private errorHandler: ErrorsHandler,
    private globalService: GlobalService,
    private snackBar: MatSnackBar,
    private _swPush: SwPush,
    private notificationService: NotificationServices,
    private bookingSlotService: BookingSlotsService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    translate.addLangs(["fr", "en"]);
    translate.setDefaultLang("fr");

    const DefaultLang =
      this.currentDoctor && this.currentDoctor.locale
        ? this.currentDoctor.locale
        : translate.getBrowserLang();
    translate.use(DefaultLang.match(/fr|en/) ? DefaultLang : "fr");
  }
  ngAfterContentChecked(): void {}

  ngOnInit() {
    
    if (this.authService.isAuthenticated() == false) {
      window.location.href = appConfig.main_origin;
    }
    this.isHeaderShow$ = this.globalService.isHeaderShow;
    this.isSpinnerShow$ = this.globalService.isSpinnerShow;
    this.currentDoctor$ = this.doctorService.currentDoctor$.pipe(
      tap(
        (doctor) => (this.currentDoctor = doctor),
      )
    );
    if ("Notification" in window && Notification.permission === "granted") {
      this.getNotification();
    } else {
      this.translate
        .get("connect.globals.enable_notifications_message")
        .subscribe((res: string) => {
          this.openSnackBar(res, "OK");
        });
    }
  }
  
  openSnackBar(message: string, action: string) {
    this.snackBar
      .open(message, action, {
        horizontalPosition: "left",
        verticalPosition: "bottom",
      })
      .onAction()
      .subscribe(() => this.subscribeToNotifications());
  }

  subscribeToNotifications() {
    this._swPush
      .requestSubscription({
        serverPublicKey: appConfig.PUBLIC_KEY_VAPID,
      })
      .then((res) => {
        const auths = JSON.parse(JSON.stringify(res));
        const body = {
          endpoint: auths.endpoint,
          auth: auths.keys.auth,
          p256dh: auths.keys.p256dh,
        };

        this.notificationService.subscibeUser(body).subscribe((t) => {
          this.getNotification();
        });
      })
      .catch((err) => console.error({ err }));
  }

  getNotification() {
    this.bookingSlotService.toConfirm().subscribe((data: any) => {
      if (data.length > 0) {
        this.notificationService.pushNotifictaionUrl().subscribe((data) => {});
      }
    });
  }

  openBookingModal(data = {}) {
    if (this.isDialogOpen) {
      this.dialog.closeAll();
      this.icondAddColor = "bg-add-primary";
    } else {
      const dialogRef = this.dialog.open(BookingDialogComponent, {
        width: "515px",
        data: data,
        panelClass: "calendar-dailog",
      });

      this.dialogRefSubscription = dialogRef.afterClosed().subscribe(() => {
        this.isDialogOpen = false;
        this.icondAddColor = "bg-add-primary";
      });

      this.icondAddColor = "bg-add-danger";
    }
    this.isDialogOpen = !this.isDialogOpen;
  }

  ngOnDestroy() {
    if (this.dialogRefSubscription) {
      this.dialogRefSubscription.unsubscribe();
    }
  }
}
