import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { Doctor } from "src/app/models/doctor";
import { DoctorService } from "src/app/services/doctor.service";
import { MassSmsService } from "src/app/services/mass-sms.service";
import { DoctorHelper } from "src/app/helpers/doctor_helper";
import Swal from "sweetalert2";
import { switchMap } from "rxjs/operators";

@Component({
  selector: "app-mass-sms",
  templateUrl: "./mass-sms.component.html",
  styleUrls: ["./mass-sms.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MassSmsComponent implements OnInit {
  currentDoctor: Doctor;
  content: string;
  unique_moroccan_contacts: number;

  constructor(
    private translate: TranslateService,
    private snackBar: SnackBarService,
    private errorHandler: ErrorsHandler,
    private router: Router,
    private doctorService: DoctorService,
    private massSmsService: MassSmsService,
    private doctorHelper: DoctorHelper
  ) {}

  ngOnInit() {
    this.getMoroccanContacts();
  }

  removePlacehoder() {
    let placeholder = this.translate.instant(
      "connect.mass_sms.enter_your_message"
    );
    if (this.content.includes(placeholder)) {
      this.content = this.content.replace(placeholder + "\n", "");
    }
  }
  getMoroccanContacts() {
    this.massSmsService
      .get_moroccan_contacts()
      .pipe(
        switchMap((contacts: number) => this.getDoctorInformation(contacts))
      )
      .subscribe(
        (currentDoctor: Doctor) => {
          if (currentDoctor) {
            this.currentDoctor = currentDoctor;
            const message = this.translate.instant(
              "connect.mass_sms.enter_your_message"
            );
            this.content =
              message + "\n\n\n" + this.doctorHelper.fullName(currentDoctor);
          }
        },
        (error) => this.snackBar.open(error.message)
      );
  }

  getDoctorInformation(contacts: number) {
    this.unique_moroccan_contacts = contacts;
    return this.doctorService.currentDoctor$;
  }

  paymentSms() {
    return this.router.navigate(["/payment-sms"]);
  }

  sendSms() {
    Swal.fire({
      title: this.translate.instant("connect.mass_sms.want_to_send"),
      type: this.translate.instant("connect.globals.confirmation_box_type"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#808285",
      confirmButtonText: this.translate.instant("connect.mass_sms.okay"),
      cancelButtonText: this.translate.instant("connect.globals.cancel"),
    }).then((result) => {
      if (result.value) {
        return this.massSmsService.create(this.content).subscribe((data) => {
          this.snackBar.open(this.translate.instant("connect.mass_sms.sent"));
          (error) => {
            this.snackBar.open(
              this.translate.instant("connect.globals.error_occured_message")
            );
          };
        });
      }
    });
  }
}
