<div *ngIf="currentDoctor">
  <section class="content-wrapper appointments-container">
    <div class="appointments-header">
      <app-print-button></app-print-button>
    </div>
    <div class="">
      <div class="mobile-filter">
        <form [formGroup]="appointment_form">
          <div class="container search-container">
            <div class="row">
              <div class="col-md-12 p-0 search-top-bar">
                <div class="top-toggle-bar" [ngClass]="[this.showSearchBar ? 'show-bar' : '']" (click)="toggleBar()">
                  <h1>{{ "connect.globals.search" | translate }}</h1> <i class="fa fa-angle-down"></i>
                </div>
              </div>
            </div>
            <div class="row my-filter-1" id="search-filters" [ngClass]="[this.showSearchBar ? 'show-filter' : '']">
              <div class="col-md-3">
                <h4 class="heading-1"> {{ "connect.appointment_table.date" | translate }} </h4>
                <div class="input-group no-wrap"> <input class="hourly_field form-control" type="text" placeholder="{{
                      'connect.appointment_table.from' | translate
                    }}" readonly [matDatepicker]="from_date_picker" name="" formControlName="appointment_from" />
                  <div class="input-group-append">
                    <mat-datepicker-toggle matSuffix [for]="from_date_picker"></mat-datepicker-toggle>
                    <mat-datepicker #from_date_picker></mat-datepicker>
                  </div>
                </div>
                <div class="input-group no-wrap mt-10"> <input class="hourly_field form-control" type="text" placeholder="{{
                      'connect.appointment_table.to' | translate
                    }}" readonly [matDatepicker]="to_date_picker" name="" formControlName="appointment_to" />
                  <div class="input-group-append">
                    <mat-datepicker-toggle matSuffix [for]="to_date_picker"></mat-datepicker-toggle>
                    <mat-datepicker #to_date_picker></mat-datepicker>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <h4 class="heading-1"> {{ "connect.appointment_table.appointment_patient" | translate
                  }} </h4>
                <div class="input-group no-wrap"> <input type="text" mdInput class="form-control clear" formControlName="patient_name" /> </div>
              </div>
              <div class="col-md-3">
                <h4 class="heading-1"> {{ "connect.appointment_table.appointment_slot_type"
                      | translate
                  }} </h4>
                <div class="input-group no-wrap">
                  <mat-form-field>
                    <mat-select class="full-width form-control" formControlName="type" placeholder="{{
                        'connect.appointment_table.select' | translate
                      }}">
                      <mat-option value="">{{ "connect.globals.all_category" | translate
                      }}</mat-option>
                      <mat-option *ngFor="let type of types" value="{{ type.value }}">{{ "connect.appointment_table." + type.viewValue
                            | translate
                        }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-3">
                <h4 class="heading-1"> {{ "connect.appointment_table.appointment_status" | translate
                  }} </h4>
                <div class="input-group no-wrap">
                  <mat-form-field>
                    <mat-select class="full-width form-control" formControlName="status" placeholder="{{
                        'connect.appointment_table.select' | translate
                      }}">
                      <mat-option value="">{{ "connect.globals.all_category" | translate
                      }}</mat-option>
                      <mat-option *ngFor="let status of appointment_status" value="{{ status }}">{{ "connect.appointment_table.appointment_form.appointment_status." +
                            status | translate
                        }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-3">
                <h4 class="heading-1"> {{ "connect.appointment_table.appointment_actions" | translate
                  }} </h4>
                <div class="btn-group inner-action-buttons"> <button class="btn-round btn btn-brand btn-block" type="button" (click)="filterBookings(this.listParams.pageNumber)"> <i class="fa fa-search"></i><br /> </button> <button type="button" class="btn-round btn btn-light btn-block border text-center reset-btn" (click)="resetData()"> <i class="fa fa-times"></i><br /> </button> </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="appointment-table table mat-elevation-z0 desktop-filter-table">
        <form [formGroup]="appointment_form">
          <mat-table #table [dataSource]="dataSource" matSort class="table-cell-block flex-mat-table">
            <ng-container matColumnDef="houraire">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <div>
                  <h6 class="table-heading"> {{ "connect.appointment_table.date" | translate }} </h6>
                  <div class="inner-content hide-mobile-header-filter">
                    <div class="input-group no-wrap"> <input class="hourly_field" type="text" placeholder="{{
                          'connect.appointment_table.from' | translate
                        }}" readonly [matDatepicker]="from_date_picker" name="" formControlName="appointment_from" />
                      <div class="input-group-append">
                        <mat-datepicker-toggle matSuffix [for]="from_date_picker"></mat-datepicker-toggle>
                        <mat-datepicker #from_date_picker></mat-datepicker>
                      </div>
                    </div>
                    <div class="input-group no-wrap mt-10"> <input class="hourly_field" type="text" placeholder="{{
                          'connect.appointment_table.to' | translate
                        }}" readonly [matDatepicker]="to_date_picker" name="" formControlName="appointment_to" />
                      <div class="input-group-append">
                        <mat-datepicker-toggle matSuffix [for]="to_date_picker"></mat-datepicker-toggle>
                        <mat-datepicker #to_date_picker></mat-datepicker>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let bookingSlot" class="cursor-pointer mat-block"> <span class="caret-icon-span"><i [className]="
                      isIconToggled === true && rowId === bookingSlot.id
                        ? 'fa fa-caret-down'
                        : 'fa fa-caret-right'
                    " style="
                      color: #22b9ff;
                      font-size: 24px;
                      vertical-align: middle;
                    "></i> {{ bookingSlot.starts_at | ToTimeFormat }} - {{ bookingSlot.ends_at | ToTimeFormat }}</span> </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <h6 class="table-heading"> {{ "connect.appointment_table.appointment_patient" | translate
                  }} </h6>
                <div class="inner-content hide-mobile-header-filter"> <input type="text" mdInput class="form-control clear" formControlName="patient_name" /> </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let bookingSlot" class="mat-block mat-border-right">
                <div *ngIf="hasConnectPlusAccess; then hasAccess; else blockAccess"></div>
                <ng-template #hasAccess> <span> <a (click)="bookingSlot.medical_record_id ? navigateMedicalRecord(bookingSlot.medical_record_id) : null" [attr.disabled]="!bookingSlot.medical_record_id ? true : null" class="medical-record-navigate">{{ bookingSlot.user_first_name }} {{ bookingSlot.user_last_name }}</a> </span> </ng-template>
                <ng-template #blockAccess> <span>
                    <p class="medical-record-navigate"> {{ bookingSlot.user_first_name }} {{ bookingSlot.user_last_name }} </p>
                  </span> </ng-template>
                <div class="multi-clr-btns-box multi-clr-btn-intable">
                  <ul>
                    <li *ngFor="let label of bookingSlot.patient_labels"> <button class="multi-clr-btn btn-white-color" [style.backgroundColor]="label.color"> {{ label.name }} </button> </li>
                  </ul>
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <h6 class="table-heading"> {{ "connect.appointment_table.appointment_slot_type"
                      | translate
                  }} </h6>
                <div class="inner-content hide-mobile-header-filter">
                  <mat-form-field>
                    <mat-select class="full-width" formControlName="type" placeholder="{{
                        'connect.appointment_table.select' | translate
                      }}">
                      <mat-option value="">{{ "connect.globals.all_category" | translate
                      }}</mat-option>
                      <mat-option *ngFor="let type of types" value="{{ type.value }}">{{ "connect.appointment_table." + type.viewValue
                            | translate
                        }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let bookingSlot" class="mat-block">
                <div *ngIf="bookingSlot.slot_type === appointmentTypes.MANUAL">
                  <div class="kt-badge kt-badge--info kt-badge--inline kt-badge--pill type-icons" *ngIf="bookingSlot.type !== bookingTypes.VIDEO"> <span>{{ "connect.appointment_table." + bookingTypes.OFFICE
                        | translate
                    }}</span> <i class="fa fa-building"></i> </div>
                  <div class="kt-badge kt-badge--danger kt-badge--inline user-kt-badge--pill type-icons" *ngIf="
                      bookingSlot.type === bookingTypes.VIDEO &&
                      bookingSlot.state === appointmentStates.AWAITING_PAYMENT
                    "> <span class="mr-2">{{ "connect.appointment_table." + bookingTypes.VIDEO
                        | translate
                    }}</span> <i class="fa fa-exclamation-circle mr-2"></i> <i class="fa fa-video"></i> </div>
                </div>
                <div class="kt-badge kt-badge--info kt-badge--inline kt-badge--pill type-icons" *ngIf="
                    bookingSlot.type === bookingTypes.OFFICE &&
                    bookingSlot.slot_type === appointmentTypes.ONLINE
                  "> <span>{{ "connect.appointment_table." + bookingTypes.OFFICE
                      | translate
                  }}</span> <i class="fa fa-building"></i> </div>
                <div class="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill type-icons" *ngIf="
                    bookingSlot.state !== appointmentStates.AWAITING_PAYMENT &&
                    bookingSlot.type === bookingTypes.VIDEO
                  "> <span>{{ "connect.appointment_table." + bookingTypes.VIDEO
                      | translate
                  }}</span> <i class="fa fa-video-camera"></i> </div>
                <div class="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill refunded-icon" *ngIf="
                    bookingSlot.type === bookingTypes.VIDEO &&
                    bookingSlot.refunded &&
                    bookingSlot.slot_type !== appointmentTypes.MANUAL
                  "> <span>{{ "connect.appointment_table." + appointmentTypes.REFUNDED
                      | translate
                  }}</span> <i class="fas fa-undo"></i> </div>
                <div class="kt-badge domicile-primary kt-badge--inline kt-badge--pill type-icons" *ngIf="bookingSlot.type === bookingTypes.HOME"> <span>{{ "connect.appointment_table." + bookingTypes.HOME | translate
                  }}</span> <i class="fa fa-home"></i> </div>
                <div class="kt-badge kt-badge--success kt-badge--inline user-kt-badge--pill type-icons" *ngIf="bookingSlot.slot_type === appointmentTypes.ONLINE"> <i class="fa fa-user"></i> </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="statut">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <h6 class="table-heading"> {{ "connect.appointment_table.appointment_status" | translate
                  }} </h6>
                <div class="inner-content hide-mobile-header-filter">
                  <mat-form-field>
                    <mat-select class="full-width" formControlName="status" placeholder="{{
                        'connect.appointment_table.select' | translate
                      }}">
                      <mat-option value="">{{ "connect.globals.all_category" | translate
                      }}</mat-option>
                      <mat-option *ngFor="let status of appointment_status" value="{{ status }}">{{ "connect.appointment_table.appointment_form.appointment_status." +
                            status | translate
                        }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let bookingSlot" class="mat-block"> <span class="mr-10 status-icon">{{ "connect.appointment_table.appointment_form.appointment_status." +
                      bookingSlot.state | translate
                  }} </span> <span class="btn icons confirm-btn btn-icon pointer" (click)="showStatus(bookingSlot.state)"><i class="{{ checkStatus(bookingSlot.state) }}"></i></span> </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef class="actions-btns">
                <h6 class="table-heading text-center"> {{ "connect.appointment_table.appointment_actions" | translate
                  }} </h6>
                <div class="inner-content-buttons hide-mobile-header-filter"> <button class="btn btn-brand btn-block" type="button" (click)="filterBookings(this.listParams.pageNumber)"> <i class="fa fa-search"></i><br /> </button> <button type="button" class="btn btn-light btn-block border text-center reset-btn" (click)="resetData()"> <i class="fa fa-times"></i><br /> </button> </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let bookingSlot" class="actions-btns mat-block centralized-btns">
                <div class="action-buttons"> <a *ngIf="
                      bookingSlot.type === bookingTypes.VIDEO &&
                      !pastDate(bookingSlot.ends_at)
                    " class="btn flag-btn btn-icon pointer ml-2" href="{{ bookingSlot.video_url }}" target="_blank"> <i class="fa fa-phone"></i> </a> <a *ngIf="
                      confirmedDoctorAssistant(bookingSlot.state) &&
                      pastDate(bookingSlot.ends_at) &&
                      selectedDate(bookingSlot.ends_at) &&
                      !bookingSlot.patient_didnt_showup
                    " class="btn grey-flag-btn btn-icon pointer ml-2" (click)="didntShowUp(bookingSlot.id)"> <i class="fas fa-ghost"></i> </a> <a *ngIf="bookingSlot.patient_didnt_showup" class="btn flag-btn btn-icon pointer ml-2"> <i class="fas fa-ghost"></i> </a> <a *ngIf="
                      pastDate(bookingSlot.starts_at) &&
                      bookingSlot.slot_type === appointmentTypes.MANUAL &&
                      bookingSlot.medical_record_id
                    " class="btn add-prescription-btn btn-icon pointer ml-2" (click)="
                      navigateMedicalRecord(bookingSlot.medical_record_id)
                    " target="_blank"> <i class="fas fa-file-alt"></i> </a> <a *ngIf="
                      (isOnline(bookingSlot.slot_type) ||
                        isHome(bookingSlot.type)) &&
                      futureDate(bookingSlot.starts_at) &&
                      confirmedBooking(bookingSlot.state)
                    " (click)="confirmBookingSlot(bookingSlot)" class="btn confirm-new-btn btn-icon pointer ml-2"> <i class="fa fa-check"></i> </a> <a *ngIf="
                      bookingHelper.isCancelable(bookingSlot) &&
                      bookingSlot.type !== bookingTypes.VIDEO
                    " class="btn delete-btn btn-icon pointer ml-2" (click)="cancelBookingSlot(bookingSlot)"> <i class="fa fa-times"></i> </a> <a *ngIf="
                      isOnline(bookingSlot.slot_type) &&
                      hoursDifference(bookingSlot.starts_at)
                    " class="btn confirm-new-btn btn-icon pointer ml-2" (click)="openBookingModal(bookingSlot)"> <i class="fas fa-paper-plane"></i> </a> <a *ngIf="
                      isOnline(bookingSlot.slot_type) &&
                      hoursDifference(bookingSlot.starts_at)
                    " class="btn edit-btn btn-icon mt-1 cursor-pointer ml-2" (click)="openBookingSlotModal(bookingSlot)"> <i class="fas fa-edit"></i> </a> </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="groupHeader" class="start_date">
              <mat-cell class="mat-group-cell" *matCellDef="let group"><b>{{ group.starts_at | customDate }} </b></mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns" (click)="toggleIcon(row)" matRipple [cdkDetailRow]="row" [cdkDetailRowTpl]="tpl"> </mat-row>
            <mat-row class="mat-group-row start_date" *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </mat-row>
          </mat-table>
          <ng-container *ngIf="dataSource && dataSource.data.length === 0">
            <h2 class="no-filter-data"> {{ "connect.appointment_table.no_result" | translate }} </h2>
          </ng-container>
        </form>
        <div class="text-right mt-2"> <button mat-raised-button color="primary" class="ml-auto mr-1" [disabled]="previousDisable" (click)="paginatorBookingSlots('previous')"> {{ "connect.globals.previous_button_text" | translate }} </button> <span class="pagination-number">{{ listParams.pageNumber }}</span> <button mat-raised-button color="primary" class="ml-auto" [disabled]="nextDisable" (click)="paginatorBookingSlots('next')"> {{ "connect.globals.next_button_text" | translate }} </button> </div>
        <ng-template #tpl let-bookingDetails>
          <div class="mat-row detail-row appointment-patient-information" [@detailExpand] style="overflow: hidden"> {{ "connect.globals.patient_phone_number" | translate }}: <a href="tel:{{ bookingDetails.user_phone_number }}">{{ bookingDetails.user_phone_number
            }}</a> </div>
          <div class="mat-row detail-row appointment-patient-information" [@detailExpand] style="overflow: hidden" *ngIf="bookingDetails.user_email"> {{ "connect.globals.patient_email" | translate }}: <a href="mailto:{{ bookingDetails.user_email }}">{{ bookingDetails.user_email
            }}</a> </div>
          <div class="mat-row detail-row appointment-patient-information" [@detailExpand] style="overflow: hidden"> {{ "connect.globals.booking_date" | translate }}: {{ bookingDetails.created_at | date : "shortDate" }} </div>
        </ng-template>
      </div>
    </div>
  </section>
</div>