import * as Sentry from "@sentry/angular-ivy";
import { ErrorHandler, Injectable } from "@angular/core";
import { environment as appConfig } from "src/environments/environment";
import { HttpErrorResponse } from "@angular/common/http";
import { SnackBarService } from './snack-bar.service'
import { TranslateService } from '@ngx-translate/core'
import { DoctorService } from "./doctor.service";
import { Doctor } from "../models/doctor";
Sentry.init({
  dsn: appConfig.sentry_url,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
});

@Injectable()
export class SentryErrorHandlerService implements ErrorHandler {

  constructor(private snackBar: SnackBarService, private translate: TranslateService, private doctorService: DoctorService)
  {
    this.doctorService.currentDoctor$.subscribe((doctor: Doctor) => {
      if (doctor) {
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: doctor.id.toString(),
            email: doctor.email,
          });
        });
      }
    });
  }

  extractError(error: any) {
    // Unwrap Angular's zone.js errors (if present)
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }
  
    // Handle string errors or Error instances directly
    if (typeof error === "string" || error instanceof Error) {
      return error;
    }
  
    // Handle HttpErrorResponse
    if (error instanceof HttpErrorResponse) {
      return this.handleHttpErrorResponse(error);
    }
  
    // Handle ErrorEvent (browser-based errors)
    if (error instanceof ErrorEvent) {
      return error.message;
    }
  
    // Handle object-based errors (generic fallback for unknown error structures)
    if (error && typeof error === 'object') {
      try {
        return JSON.stringify(error);
      } catch (e) {
        return "Unknown error (unable to stringify)";
      }
    }
  
    // If error is undefined or null, return a default message
    return "Unknown error occurred";
  }
  
  handleHttpErrorResponse(error: HttpErrorResponse) {
    // Client-side or network error
    if (error.error instanceof ErrorEvent) {
      return `Client-side error: ${error.error.message}`;
    }
  
    // Server-side error with body message
    if (error.error && typeof error.error === 'string') {
      return `Server returned code ${error.status}, message: "${error.error}"`;
    }
  
    // Server-side error without a body message
    if (error.message) {
      return `Server returned code ${error.status}, message: "${error.message}"`;
    }
  
    return `Server returned code ${error.status} with no message`;
  }
  

  handleError(error: any) {
    let extractedError = this.extractError(error) || "Handled unknown error";
    if(error instanceof HttpErrorResponse && error.status == 0 && error.statusText == "Unknown Error")
    {
      this.snackBar.open(this.translate.instant('connect.globals.internet_connection_warning'));
    }
    else
    {
      Sentry.captureException(extractedError);
      console.error(extractedError);
    }
  }
}
