import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  AfterContentChecked,
  ChangeDetectionStrategy,
  Inject,
  Renderer2,
  ViewEncapsulation,
} from "@angular/core";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { TranslateService } from "@ngx-translate/core";
import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  NgForm,
  UntypedFormArray,
  UntypedFormControl,
} from "@angular/forms";
import { CountryISO, SearchCountryField } from "ngx-intl-tel-input";
import { Establishment } from "src/app/models/establishment";
import { EstablishmentsService } from "src/app/services/establishments.service";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { environment as appConfig } from "../../../environments/environment";
import { GlobalService } from "src/app/services/global.service";
import { DOCUMENT } from "@angular/common";
import { Speciality } from "src/app/models/speciality";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EditProfileComponent implements OnInit {
  currentDoctor: Doctor;
  profileForm: UntypedFormGroup;
  cities: String[];
  languages: string[];
  doctorEstablishments: Establishment[] = [];
  isArabicCountry: boolean = false;
  public dosageForm: UntypedFormArray;
  establishments: any;
  isValidate: boolean = false;
  public establishmentFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredEstablishments: ReplaySubject<[]> = new ReplaySubject<[]>(1);
  protected _onDestroy = new Subject<void>();
  private establishment = new Establishment();
  titles = [
    { value: "dr", viewValue: "Dr." },
    { value: "pr", viewValue: "Pr." },
    { value: "none", viewValue: "-" },
  ];

  genders = [
    { value: "male", viewValue: "male" },
    { value: "Women", viewValue: "female" },
  ];
  isScriptLoaded: boolean = false;
  profilePic: any;
  images: File[];
  isUploading: boolean = false;
  profilePictureIsUploading: boolean = false;
  profilePicturePreview: string;
  imageSize: number = 20971520;
  constructor(
    private errorHandler: ErrorsHandler,
    private doctorService: DoctorService,
    private cdref: ChangeDetectorRef,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private globalService: GlobalService,
    private snackBar: SnackBarService,
    private establismentService: EstablishmentsService,
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2
  ) {}

  ngOnInit() {
    this.loadGoogleMapScript().then(() => {
      this.isScriptLoaded = true;
    });

    this.getDoctorInfo();
    this.doctorService.currentDoctor$.subscribe((currentDoctor: Doctor) => {
      if (currentDoctor) {
        this.currentDoctor = currentDoctor;
        this.profilePic = currentDoctor.photo_1;
      }
    });
  }

  loadGoogleMapScript() {
    return new Promise((resolve, reject) => {
      const script = this.renderer2.createElement("script");
      script.type = "text/javascript";
      script.src = appConfig.google_map_script;
      script.text = ``;
      script.async = false;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      this.renderer2.appendChild(this.document.body, script);
    });
  }

  deleteEstablishment(index_number) {
    this.doctorEstablishments.splice(index_number, 1);
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  validate(event) {
    this.isValidate = false;
    if (event.length > 0) {
      if (!(event.startsWith("http://") || event.startsWith("https://"))) {
        this.isValidate = true;
      }
    }
  }

  getDoctorInfo() {
    this.globalService.showSpinner();
    this.doctorService.getCities().subscribe((data: String[]) => {
      this.cities = data;
    });

    this.doctorService.getLanguages().subscribe((data: string[]) => {
      this.languages = data;
    });

    this.doctorService.currentDoctor$.subscribe((currentDoctor) => {
      if (currentDoctor) {
        this.globalService.hideSpinner();
        this.currentDoctor = currentDoctor;
        if (
          appConfig.arabic_countries.some(
            (country) => country == this.currentDoctor.country
          )
        ) {
          this.isArabicCountry = true;
        }
        this.doctorEstablishments = currentDoctor.establishments;
        this.initProfileForm();
      }
    });
  }

  initProfileForm() {
    this.profileForm = this.formBuilder.group({
      id: [this.currentDoctor.id],
      address: [this.currentDoctor.address],
      address_neighborhood: [this.currentDoctor.address_neighborhood],
      postal_code: [this.currentDoctor.postal_code],
      phone_number: [this.currentDoctor.phone_number, [Validators.required]],
      cellphone_number: [
        this.currentDoctor.cellphone_number,
        [Validators.required],
      ],
      address_additional_1: [this.currentDoctor.address_additional_1],
      address_additional_2: [this.currentDoctor.address_additional_2],
      city_id: [this.currentDoctor.city_id],
      phone_number_secondary: [this.currentDoctor.phone_number_secondary],
      website: [this.currentDoctor.website],
      title: [this.currentDoctor.title],
      gender: [this.currentDoctor.gender],
      first_name: [{ value: this.currentDoctor.first_name, disabled: true }],
      last_name: [{ value: this.currentDoctor.last_name, disabled: true }],
      specialities: [
        { value: this.getSpecialities(this.currentDoctor.specialities), disabled: true },
      ],
      language_ids: [this.currentDoctor.language_ids],
      started_practice: [this.currentDoctor.started_practice],
      ice: [this.currentDoctor.ice],
      password: [""],
      password_confirmation: [""],
      degrees: [this.currentDoctor.degrees],
      presentation: [this.currentDoctor.presentation],
      video_consultation_price: [this.currentDoctor.video_consultation_price],
      rib: [{ value: this.currentDoctor.rib, disabled: true }],
      first_name_ar: [this.currentDoctor.first_name_ar],
      last_name_ar: [this.currentDoctor.last_name_ar],
      degrees_ar: [this.currentDoctor.degrees_ar],
      presentation_ar: [this.currentDoctor.presentation_ar],
      establishment_ids: [""],
      photo_1_cache: [this.currentDoctor.photo_1],
      home_visit_info: [this.currentDoctor.home_visit_info],
      address_ar: [this.currentDoctor.address_ar],
      address_neighborhood_ar: [this.currentDoctor.address_neighborhood_ar],
      address_additional_1_ar: [this.currentDoctor.address_additional_1_ar],
      address_additional_2_ar: [this.currentDoctor.address_additional_2_ar],
    });
    this.establismentService.get("").subscribe((data: []) => {
      this.establishments = data;
      this.filteredEstablishments.next(this.establishments.slice());
      this.establishmentFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterEstablishments();
        });
    });
  }

  getSpecialities(specialities:Array<Speciality>): string 
  {
    const specialityNames = specialities.map((speciality: Speciality) => {
      return speciality.name
    });
    return specialityNames.join(', ');
  }

  protected filterEstablishments() {
    if (!this.establishments) {
      return;
    }
    let search = this.establishmentFilterCtrl.value;
    if (!search) {
      this.filteredEstablishments.next(this.establishments.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.establismentService.get(search).subscribe((data: []) => {
      this.establishments = data;
      this.filteredEstablishments.next(
        this.establishments.filter(
          (establishment) =>
            establishment.name.toLowerCase().indexOf(search) > -1
        )
      );
    });
  }

  addEstablishment() {
    //Establishment
    this.establishment = {
      full_name:
        this.profileForm.value.establishment_ids.split("/establishment/")[0],
      id: this.profileForm.value.establishment_ids.split("/establishment/")[1],
    };

    //Ids of the chosen establishments on the list (that was saved before)
    let currenteEtablishmentsIds = this.currentDoctor.establishments.map(
      ({ id }) => id
    );

    //Id of chosen establishment on the options list
    let chosenEtablishmentsId = this.establishment.id;

    if (
      !currenteEtablishmentsIds.some((id) => id === chosenEtablishmentsId) &&
      chosenEtablishmentsId != null
    ) {
      this.doctorEstablishments.push(this.establishment);
    }
  }

  doctorInfo() {
    this.doctorService.get().subscribe(
      (data) => (this.currentDoctor = data),
      (error) => this.errorHandler.handleError(error.message)
    );
  }

  uploadImages(event)
  {
    const files = event.target.files;
    if (!files || files.length == 0) return;
    this.isUploading = true;
    this.images = files;
    const formData = new FormData();
    for (var i = 0; i < this.images.length; i++) {
      if (this.images[i].size < this.imageSize) {
        formData.append("images[]", this.images[i]);
      } else {
        this.isUploading = false;
        return this.snackBar.open(
          this.translate.instant(
            "connect.globals.image_size_error_occured_message"
          )
        );
      }
    }

    this.doctorService.uploadImages(formData).subscribe(
      (data: Doctor) => {
        this.snackBar.open(
          this.translate.instant("connect.profile.photo_added")
        ); 
        this.doctorInfo();
        this.currentDoctor = data;
        this.initProfileForm();
      },
      (data: any) => 
      {
        this.currentDoctor.images = data.error.images;
        this.initProfileForm();
        const errorMessage = data.error.errorMessage;
        this.errorHandler.handleError(errorMessage)
      }
    ).add( () =>
    {
      this.isUploading = false
    });
  }
  deletePhoto(id) {
    this.doctorService.deletePhoto(id).subscribe(
      (data: Doctor) => {
        this.snackBar.open(
          this.translate.instant("connect.profile.deleted_image")
        );
        this.currentDoctor = data;
        this.initProfileForm();
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  updateProfilePicture(event): void {
    this.profilePictureIsUploading = event.target.files.length > 0;
    const image = event.target.files[0];
    if (image.size < this.imageSize) {
      const blob = new Blob([image], { type: "image/jpeg" });
      const url = URL.createObjectURL(blob);
      this.profilePic = url;

      this.doctorService.addProfilePicture(image).subscribe(
        (data: Doctor) => {
          this.snackBar.open(
            this.translate.instant("connect.profile.photo_added")
          ),
            this.doctorInfo();
          this.initProfileForm();
          this.profilePictureIsUploading = false;
        },
        (error) =>
          this.errorHandler.handleError(
            this.translate.instant("connect.globals.error_occured_message")
          )
      );
    } else {
      this.profilePictureIsUploading = false;
      return this.snackBar.open(
        this.translate.instant(
          "connect.globals.image_size_error_occured_message"
        )
      );
    }
  }

  SaveDetails() {
    const types = [
      "phone_number",
      "cellphone_number",
      "phone_number_secondary",
    ];
    types.forEach(function (type) {
      if (
        this.profileForm.controls[type] &&
        this.profileForm.controls[type].value &&
        this.profileForm.controls[type].value["internationalNumber"]
      ) {
        const phone_number = this.profileForm.controls[type].value[
          "internationalNumber"
        ].replace(/-|\s/g, "");
        this.profileForm.controls[type].setValue(phone_number);
      }
    }, this);
    const selectedIds = this.doctorEstablishments.map(({ id }) => id);
    this.profileForm.value.establishment_ids = selectedIds;
    this.doctorService.updateProfile(this.profileForm.value).subscribe(
      (data) => {
        this.snackBar.open(
          this.translate.instant("connect.profile.save_changes")
        );
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }
}
