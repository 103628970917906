import {
  Component,
  OnInit,
  Input,
  Inject,
  ViewChild,
  ViewEncapsulation,
  Renderer2
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BookingSlotsService } from "src/app/services/booking-slots.service";
import {
  AbstractControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  ValidatorFn,
} from "@angular/forms";
import { startsAtValidator } from "src/app/validators/start_at_validator";
import moment from 'moment-timezone';
import { SnackBarService } from "src/app/services/snack-bar.service";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import { CountryISO, SearchCountryField } from "ngx-intl-tel-input";
import { BookingSlot } from "src/app/models/booking-slot";
import { DoctorService } from "src/app/services/doctor.service";
import { Doctor } from "src/app/models/doctor";
import { DateTimeAdapter } from "@danielmoncada/angular-datetime-picker";
import { SlotType } from "src/app/enums/SlotType.enum";
import { BookingType } from "src/app/enums/bookingType.enum";
import { ReplaySubject, Subject } from "rxjs";
import { MatSelect } from "@angular/material/select";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import { MedicalRecordService } from "src/app/services/medical-record.service";
import { Patient } from "src/app/models/medicalRecordPatient.model";
import { TranslateService } from "@ngx-translate/core";
import { BookingHelper } from "src/app/helpers/booking_helper";
import { SettingsService } from "src/app/services/settings.service";
import Swal from "sweetalert2";
import { MedicalRecord } from "src/app/models/medical_records";
import { Router } from "@angular/router";
import { PlanType } from "src/app/enums/planType.enum";
import { AlertService } from "src/app/services/alert.service";
import { SwalType } from "src/app/enums/swalType.enum";
import { BlockedDay } from "src/app/models/blocked_day";
import { PhoneValidationHelper } from "src/app/helpers/phone_validation_helper";

@Component({
  selector: "booking-modal",
  templateUrl: "./booking-dialog.component.html",
  styleUrls: ["./booking-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BookingDialogComponent implements OnInit {
  @Input() bookingSlot: BookingSlot;
  @Input() doctor: Doctor;
  bookingSlotForm: UntypedFormGroup;
  bookingForm: UntypedFormGroup;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.Morocco,
    CountryISO.Algeria,
    CountryISO.Tunisia,
  ];
  validPhoneNumber = true;
  localDateFormat = 'YYYY-MM-DDTHH:mm:ssZ'
  isNewBooking = true;
  isVideoBooking = false;
  isMindVideoBooking = false;
  isModifyBooking = false;
  isEditable = true;
  isRecord = true;
  isPatientInfoEditable = false;
  isConfirmBooking = true;
  isDeletable: Boolean = false;
  isAdding:Boolean = false;
  isCancelable: Boolean = false;
  isVideoConsultation: boolean;
  isMindPartner: boolean = false;
  videoBooking: boolean = false;
  last_name: string = "";
  user_locale: String = 'fr';
  patientForm: UntypedFormGroup;
  patient = <Patient>{};
  medical_record = <MedicalRecord>{}
  medical_records: any;
  country: string = "MA";
  disable: Boolean = false;
  lastNameValue: Boolean = true;
  age: any;
  speciality:string = '';
  // Current time
  clinic_resources: any;
  clinic: boolean;
  timeNow = moment();
  interval: any;
  patient_labels: any;
  public medicalFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredMedicalRecords: ReplaySubject<[]> = new ReplaySubject<[]>(1);
  protected _onDestroy = new Subject<void>();
  @ViewChild("singleSelect", { static: true }) singleSelect: MatSelect;

  constructor(
    public dialogRef: MatDialogRef<BookingDialogComponent>,
    private medicalRecordService: MedicalRecordService,
    private bookingSlotsService: BookingSlotsService,
    private doctorService: DoctorService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private bookingHelper: BookingHelper,
    private snackBar: SnackBarService,
    private errorHandler: ErrorsHandler,
    private settingsService: SettingsService,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private phoneValidationHelper:PhoneValidationHelper,
    private renderer: Renderer2,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.getSettings();
    if (this.data.bookingSlot) {
      if (this.data.bookingSlot.country) {
        this.country = this.data.bookingSlot.country;
      }
      this.isModifyBooking = true;
      this.bookingSlot = this.data.bookingSlot;
      if (this.data.bookingSlot.user_last_name) {
        this.last_name = this.data.bookingSlot.user_last_name;
      } else {
        this.lastNameValue = false;
      }
      if (this.data.bookingSlot.user_locale) {
        this.user_locale = this.data.bookingSlot.user_locale
      } else {
        this.user_locale = 'fr'
      }
      if (this.data.bookingSlot.medical_record_id) {
        this.isRecord = false;
      }

      this.isCancelable = this.bookingHelper.isCancelable(
        this.data.bookingSlot
      );

      if (this.data.bookingSlot.slot_type == SlotType.BLOCKING) {
        this.isDeletable = true;
      }
      if (this.data.bookingSlot.patient_profile) {
        this.disable = true;
      }
    } else {
      this.bookingSlot = this.data;
      this.last_name = this.data.user_last_name;
    }

    if(this.data.bookingSlot && this.data.bookingSlot.resourceId){
      this.speciality = this.data.bookingSlot.resourceId;
    }else {
      this.speciality = this.data.resourceId;
    }

    this.filteredMedicalRecords.next(this.medical_records);
    this.medicalFilterCtrl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterMedicalRecords();
      });
    if(this.isEditDialog && this.doctor?.clinic)
    {
      const data = {
        booking_slot_id: this.data.id,
        department_id: this.data.department?._id,
      }
      this.doctorService.getDepartmentAvailableResources(data) // request to /available_clinic_resources
      .subscribe((clinic_resources: [] | null)=>
      {
        this.clinic_resources = (clinic_resources?.length > 0) ? clinic_resources : []
      });
    }
    this.doctorService.currentDoctor$.subscribe((currentDoctor: Doctor) => {
      if (currentDoctor) {
        moment.tz.setDefault(currentDoctor.time_zone); // force the correct doctor's timezone
        this.interval = setInterval(() => {
          // re-Init current time each 1 second
          //Used in: {min} value for time input on booking dialog (owl-date-time)
          this.timeNow = moment.tz(currentDoctor.time_zone)
          }, 1000);
        this.doctor = currentDoctor;
        this.clinic = currentDoctor.clinic;
        this.patient_labels = currentDoctor.patient_labels;
        if (!this.isEditDialog && currentDoctor.clinic) this.clinic_resources = currentDoctor.clinic_resources
        this.initBookingForm();
      }
    });

    if (this.isEditDialog && this.doctor?.clinic) {
      const data = {
        booking_slot_id: this.data.id,
        department_id: this.data.department?._id,
      };
      this.doctorService.getDepartmentAvailableResources(data)
        .subscribe(
          (clinic_resources: [] | null) => {
            this.clinic_resources = (clinic_resources?.length > 0) ? clinic_resources : [];
          },
          error => {
            this.errorHandler.handleError(
              this.translate.instant("connect.globals.error_occured_message")
            );
          }
        );
    }

    if (this.disable) {
      this.bookingForm = this.formBuilder.group({
        id: [this.bookingSlot.id],
        starts_at: [this.bookingSlot.starts_at],
        ends_at: [this.bookingSlot.ends_at],
        user_locale: [this.bookingSlot.user_locale],
        user_first_name: [this.bookingSlot.user_first_name],
        user_age: [this.bookingSlot.user_age],
        user_last_name: [this.bookingSlot.user_last_name],
        user_email: [this.bookingSlot.user_email],
        user_phone_number: [this.bookingSlot.user_phone_number],
        booking_slot_type_id: [this.bookingSlot.booking_slot_type_id],
        medical_record_id: [this.bookingSlot.medical_record_id],
        is_video: [this.videoBooking],
        speciality: [this.speciality]
      });
    }
  }

  get isEditDialog()
  {
    return (this.data.id && this.data.department?._id) ? true : false;
  }

  defaultAvailableMessage()
  {
    return "connect.online-booking." + (this.clinic_resources?.length > 0 ? "choose_resource" : "no_resource_available_for_today");
  }

  didntShowUp (bookingSlotId:string, buttonRef: any)
  {
    if(this.bookingSlot.patient_didnt_showup)
    {
      Swal.fire({
        title: this.translate.instant('connect.globals.already_not_show_up'),
        type: this.translate.instant('connect.globals.confirmation_box_type'),
      }).then((result) => {})
    }
    else
    {
      Swal.fire({
        title: this.translate.instant('connect.globals.patient_absent'),
        text: this.translate.instant('connect.globals.confirmation_box_text'),
        type: this.translate.instant('connect.globals.confirmation_box_type'),
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#808285',
        //allowOutsideClick: false,
        heightAuto: false,
        confirmButtonText: this.translate.instant('connect.appointment_table.continue'),
        cancelButtonText: this.translate.instant('connect.globals.cancel')
      }).then((result) => 
      {
        if (result.value)
        {
          this.renderer.setProperty(buttonRef, 'disabled', true);
          return this.bookingSlotsService.didntShowUp(bookingSlotId, true).subscribe
          (
            (data:any) =>
            {
              if(data.patient_didnt_showup)
              {
                this.renderer.setProperty(buttonRef, 'disabled', false);
                this.bookingSlot.patient_didnt_showup = data.patient_didnt_showup
                Swal.fire(this.translate.instant('connect.globals.not_show_up'))
              }       
            },
            error => this.errorHandler.handleError(this.translate.instant('connect.globals.try_again_message'))
          )
        }
      })
    }
  }

  confirmedDoctorAssistant(state: string) : boolean
  {
    return (state === 'confirmed_doctor' || state === 'confirmed_assistant')
  }

  pastDate(ends_at: any) : boolean
  {
    const currentDate = moment();
    const endsAtDate = moment(ends_at);
    return endsAtDate.isBefore(currentDate);
  }

  selectChangeHandler(event) {
    var index = this.doctor.booking_slot_types.findIndex(
      (p) => p.id == event.target.value
    );
    var slot = this.doctor.booking_slot_types[index];
    var starts_at = this.bookingSlotForm.controls["starts_at"].value;
    if (slot.duration) {
      this.bookingSlotForm.controls["ends_at"].setValue(
        moment(starts_at).add(slot.duration, "minutes").toDate()
      );
    } else {
      this.bookingSlotForm.controls["ends_at"].setValue(
        moment(starts_at)
          .add(this.doctor.average_booking_duration, "minutes")
          .toDate()
      );
    }
  }

  getSettings() {
    this.settingsService.get().subscribe((data: any) => {
      this.isVideoConsultation = data.video_consultation;
      if (this.isBlocking) {
        this.isVideoConsultation = false;
        this.isMindPartner = false;
      }
    });
  }

  onChangeEvent(event) {
    this.videoBooking = event;
  }

  onMindVideoBookingChangeEvent(event) {
    this.isMindVideoBooking = event;
  }

  protected filterMedicalRecords() {
    let search = this.medicalFilterCtrl.value;
    if (!search) {
      if (this.medical_records) {
        this.filteredMedicalRecords.next(this.medical_records.slice());
      }
      return;
    } else {
      this.last_name = search;
      this.lastNameValue = true;
      search = search.toLowerCase();
    }
    if (this.medicalFilterCtrl.value.length >= 3) {
      const filter = "name=" + search;
      this.bookingSlotsService
        .autocomplete_patient(filter)
        .subscribe((data: []) => {
          this.medical_records = data;
          this.filteredMedicalRecords.next(this.medical_records.slice());
        });
    }
  }
  calcuateBookingSlotEnds() {
    const startsAt = moment(this.bookingSlotForm.controls["starts_at"].value);
    const durationInMinutes = this.doctor.average_booking_duration;
    const endsAt = startsAt.clone().add(durationInMinutes, "minutes").format();
    this.bookingSlotForm.controls["ends_at"].setValue(endsAt);

    if (this.doctor.clinic)
    {
      const data = {      
        department_id: this.bookingSlot.department?._id,
        starts_at: startsAt.format(),
        ends_at: endsAt
      }
      this.doctorService.getDepartmentAvailableResources(data)
      .subscribe((clinic_resources)=>
      {
        this.clinic_resources = clinic_resources
      });
    }
  }
  handleSelectChange(event) {
    if (event) {
      this.bookingSlotForm.controls["user_first_name"].setValue(
        event.split("&&")[0]
      );
      this.bookingSlotForm.controls["user_last_name"].setValue(
        event.split("&&")[1]
      );
      this.bookingSlotForm.controls["user_phone_number"].setValue(
        event.split("&&")[2]
      );
      // TODO revert this once back to searching by medical records
      // this.country = event.split('&&')[4].replace(/\s/g, '')
      this.last_name = event.split("&&")[1];
      this.lastNameValue = true;
    }
  }

  initBookingForm() {
    const starts_at = this.data.bookingSlot !== undefined ? this.data?.bookingSlot.starts_at : moment.utc(this.data.starts_at).format(this.localDateFormat)
    const ends_at = this.data.bookingSlot !== undefined ? moment(this.data?.bookingSlot.ends_at) : moment.utc(this.data.ends_at).format(this.localDateFormat)
    // If the bookingSlot has an ID is should be updated instead
    this.isNewBooking = !this.bookingSlot.id;
    this.isVideoBooking = this.bookingSlot.type === BookingType.VIDEO;
    this.isMindPartner = this.doctor.mind_partner;
    this.isRecord = !this.bookingSlot.id;
    this.speciality = (this.speciality == undefined || this.speciality == null) ? '' : this.speciality

    // Init this.isConfirmBooking with false value
    this.bookingSlotState();

    this.setupDatesCorrectly();

    this.checkIfEditable();

    const isSlotTypeInclude = [SlotType.MANUAL, SlotType.ONLINE].includes(
      this.bookingSlot.slot_type
    );
    if (this.isNewBooking || (isSlotTypeInclude && this.isEditable)) {
      this.isPatientInfoEditable = true;
    }
    
    this.bookingSlotForm = this.formBuilder.group({
      id: [this.bookingSlot.id],
      starts_at: [this.bookingSlot.starts_at, [Validators.required, startsAtValidator] ],
      ends_at: [this.bookingSlot.ends_at, Validators.required],
      user_locale: [
        {
          value: this.bookingSlot.user_locale ?? 'fr',
          disabled: !this.isPatientInfoEditable || this.disable,
        },
        Validators.required,
      ],
      user_first_name: [
        {
          value: this.bookingSlot.user_first_name,
          disabled: !this.isPatientInfoEditable || this.disable,
        },
        Validators.required,
      ],
      user_age: [
        {
          value: this.bookingSlot.user_age,
          disabled: !this.isPatientInfoEditable || this.disable,
        }
      ],
      user_last_name: [
        {
          value: this.bookingSlot.user_last_name,
          disabled: !this.isPatientInfoEditable || this.disable,
        },
        Validators.required,
      ],
      user_email: [
        {
          value: this.bookingSlot.user_email,
          disabled: !this.isPatientInfoEditable || this.disable,
        },
      ],
      user_phone_number: [
        {
          value: this.bookingSlot.user_phone_number,
          disabled: !this.isPatientInfoEditable || this.disable,
        },
        [Validators.required, Validators.min(10), Validators.max(14)],
      ],
      booking_slot_type_id: [this.bookingSlot.booking_slot_type_id],
      medical_record_id: [this.bookingSlot.medical_record_id],
      is_video: [this.bookingSlot.type === BookingType.VIDEO],
      is_mind: [this.bookingSlot.is_mind],
      speciality: [this.speciality]
    });

    this.updateClinicValidators(["user_age", "speciality"]);

    const phoneControl = this.bookingSlotForm.get('user_phone_number');
    phoneControl?.valueChanges.subscribe((phoneElement) =>
    {
      this.validPhoneNumber = this.phoneValidationHelper.validatePhoneNumber(phoneControl, phoneElement?.countryCode);
      this.phoneValidationHelper.setPhoneNumberErrors(phoneControl, this.validPhoneNumber);
    });

    if (!this.isEditable) {
      this.bookingSlotForm.disable();
    }

    this.dateTimeAdapter.setLocale(this.doctor.locale);
  }

  get isVideoOrMind()
  {
    return  this.bookingSlot.type == BookingType.VIDEO || this.bookingSlot.is_mind || !!this.bookingSlot?.user_phone_number
  }

  updateClinicValidators(fields: string[])
  {
    const validators: ValidatorFn[] | null = this.clinic ? [Validators.required] : null;

    fields.forEach((field: string) =>
    {
      const control = this.bookingSlotForm.get(field);
      if (control) 
      {
        const currentValidators = control.validator ? [control.validator] : null;
        if (JSON.stringify(validators) !== JSON.stringify(currentValidators))
        {
          control.setValidators(validators);
          control.updateValueAndValidity();
        }
      }
    });
  }

  isMedicalRecord() {
    if (!this.isValid()) return;
    this.isAdding=true

    if (this.disable) {
      this.bookingForm.controls["starts_at"].setValue(
        this.bookingSlotForm.value.starts_at
      );
      this.bookingForm.controls["ends_at"].setValue(
        this.bookingSlotForm.value.ends_at
      );
      this.bookingForm.controls['user_locale'].setValue(
        this.bookingSlotForm.value.user_locale
      );
      this.bookingForm.controls["is_video"];
      this.bookingForm.controls["is_mind"];
      this.bookingSlotsService.create(this.bookingForm.value).subscribe(
        (data) => {
          this.isAdding=false
          this.snackBar.open(
            this.translate.instant("connect.online-booking.appointment_added")
          );
          return this.onNoClick(true);
        },
        (error) =>
          this.errorHandler.handleError(
            this.translate.instant("connect.globals.error_occured_message")
          )
      );
    } else {

      const filter =
        "phone_number=" +
        this.bookingSlotForm.value.user_phone_number.e164Number;
      this.medicalRecordService.all(filter).subscribe(
        (data: Patient[]) => {
          if (data.length == 0) {
            this.createNewMedicalRecord();
          } else {
            this.bookAnAppointment(data);
          }
        },
        (error) =>
          this.errorHandler.handleError(
            this.translate.instant("connect.globals.error_occured_message")
          )
      );
    }
  }

  onLastNameChange(event){
    this.bookingSlotForm.get('user_last_name').setValue(event.target.value)
  }

  onAgeChange(event) {
    this.age = event.target.value;
  }

  onSpecialityChange(event) {
    this.speciality = event.target.value;
  }

  createNewMedicalRecord(bookAnAppointment: boolean = true) // bookAnAppointment = false to avoid creating bookingslot when it's already generated.
  {
    this.medical_record.first_name =
      this.bookingSlotForm.controls["user_first_name"].value;
    this.medical_record.last_name = this.last_name;
    let phone_number =
      this.bookingSlotForm.controls["user_phone_number"].value["e164Number"];
    if (phone_number) {
      this.medical_record.phone_number = phone_number.replace(/-|\s/g, "");
    }
    this.medical_record.email = this.bookingSlotForm.controls["user_email"].value || null;
    this.medical_record.booking_slot_id = this.bookingSlotForm.controls['id'].value
    
    if(bookAnAppointment == false && this.doctor.plan_type == PlanType.CONNECT)
    {
      this.alertService.notAllowedMedicalRecord()
      return;
    }
    this.medicalRecordService.create(this.medical_record).subscribe((data) => {
      if(bookAnAppointment)
      {
        this.bookAnAppointment(data);
      }
      else
      {
        const title= this.translate.instant('connect.online-booking.medical_record_created');
        const text= this.translate.instant('connect.online-booking.more_details');
        const yesButton = this.translate.instant('connect.online-booking.yes');
        const noButton = this.translate.instant('connect.online-booking.no');
        this.alertService.showConfirmation(title, text, yesButton, noButton, SwalType.Question).then((result) => 
        {
          if (result.value) {
            this.snackBar.open(this.translate.instant('connect.medical-dailog.medical_record_added'))
            this.medicalRecordService.navigateToMedicalRecordPatient(data['id']);
          }
        });
      }
      return this.onNoClick(true)
    },
    (error) => {
      this.snackBar.open(
        this.translate.instant("connect.globals.error_occured_message")
      );
    });
  }

  bookAnAppointment(medicalRecord) {
    let user_phone_number =
      this.bookingSlotForm.controls["user_phone_number"].value["e164Number"];
    if (user_phone_number) {
      let interNumber = user_phone_number;
      this.bookingSlotForm.controls["user_phone_number"].setValue(interNumber);
      this.bookingSlotForm.controls["user_last_name"].setValue(this.last_name);
      if (medicalRecord.length > 0) {
        this.bookingSlotForm.controls["medical_record_id"].setValue(
          medicalRecord[0].id
        );
      } else {
        this.bookingSlotForm.controls["medical_record_id"].setValue(
          medicalRecord.id
        );
      }
      this.bookingSlotForm.controls["is_video"];
      this.bookingSlotForm.controls["is_mind"];
      let bookingFormValues = {
        ...this.bookingSlotForm.value,
        user_age: this.age,
        clinic_resource_id: this.speciality,
      };
      this.bookingSlotsService.create(bookingFormValues).subscribe(
        (data) => {
          this.snackBar.open(
            this.translate.instant("connect.online-booking.appointment_added")
          );
          return this.onNoClick(true);
        },
        (error) =>
          this.errorHandler.handleError(
            this.translate.instant("connect.globals.error_occured_message")
          )
      );
    } else {
      this.errorHandler.handleError(
        this.translate.instant("connect.globals.error_occured_message")
      );
      this.onNoClick(true);
    }
  }

  isValid()
  {
    this.bookingSlotForm.get('starts_at').updateValueAndValidity();
    if(!this.bookingSlotForm.valid){
      Object.keys(this.bookingSlotForm.controls).forEach(controlName => {
        this.bookingSlotForm.get(controlName).markAsTouched();
      });
      return false;
    }
    return true;
  }

  updateAnAppointment() {
    if (!this.isValid()) return;
    //update only if e164Number exists
    var userPhoneNumberControl =
      this.bookingSlotForm.controls["user_phone_number"];
    if (
      userPhoneNumberControl &&
      userPhoneNumberControl.value?.e164Number !== undefined
    ) {
      this.bookingSlotForm.value["user_phone_number"] =
        userPhoneNumberControl.value["e164Number"];
    }
    this.bookingSlotForm.value.ends_at = this.bookingSlotForm.controls.ends_at.value;
    this.bookingSlotForm.value.user_locale = this.bookingSlotForm.controls.user_locale.value;
    this.bookingSlotForm.controls["is_video"];
    this.bookingSlotForm.controls["is_mind"];
    let bookingFormValues = {
      ...this.bookingSlotForm.value,
      clinic_resource_id: this.speciality,
      ...(this.bookingSlotForm.value.user_age ? {} : { user_age: this.age }) // to avoid overriding age
    };

    this.bookingSlotsService.update(bookingFormValues).subscribe(
      (data) => {
        this.snackBar.open(
          this.translate.instant("connect.online-booking.appointment_updated")
        );
        return this.onNoClick(true);
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  deleteAnAppointment(bookingSlotId) {
    this.bookingSlotsService.delete(bookingSlotId).subscribe(
      (data) => {
        this.snackBar.open(
          this.translate.instant("connect.online-booking.booking_deleted")
        );
        return this.onNoClick(true);
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.calendar.error_occurred")
        )
    );
  }

  cancelAnAppointment(bookingSlotId) {
    this.bookingSlotsService.cancel(bookingSlotId).subscribe(
      (data) => {
        this.snackBar.open(
          this.translate.instant("connect.online-booking.booking_deleted")
        );
        return this.onNoClick(true);
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.calendar.error_occurred")
        )
    );
  }

  confirmBookingSlot(bookingSlotId) {
    this.bookingSlotsService.confirm(bookingSlotId).subscribe(
      (data) => {
        return this.snackBar.open(
          this.translate.instant("connect.calendar.booking_confirmed")
        );
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.calendar.error_occurred")
        )
    );
  }

  onNoClick(close): void {
    this.dialogRef.close(close);
  }

  get isBlocking() {
    return this.bookingSlot.slot_type === SlotType.BLOCKING;
  }

  blockSlot() {
    this.bookingSlotsService
      .blockInterval({...this.bookingSlotForm.value, resourceId: this.data.resourceId})
      .subscribe(
        (data:BlockedDay) => 
        {
          const message = data.message ? 'not_have_availabilities' : 'booking_block';
          this.snackBar.open(this.translate.instant('connect.calendar.' + message));
          this.dialogRef.close();
        },
        (error) => {
          this.onNoClick(true);
          this.errorHandler.handleError(
            this.translate.instant("connect.calendar.not_have_availabilities")
          );
        }
      );
  }

  unblockSlot() {
    this.bookingSlotsService.delete(this.bookingSlot.id).subscribe(
      (data) => {
        this.snackBar.open(
          this.translate.instant("connect.calendar.booking_unblock")
        );
        this.dialogRef.close();
      },
      (error) => {
        this.errorHandler.handleError(error.error.message);
      }
    );
  }

  canBeBlocked() {
    return (
      !this.bookingSlot.id &&
      !this.bookingSlot.medical_record_id &&
      this.bookingSlot.starts_at > new Date()
    );
  }

  canBeUnblocked() {
    return (
      this.bookingSlot.id &&
      this.bookingSlot.slot_type === SlotType.BLOCKING &&
      new Date(this.bookingSlot.ends_at) > new Date()
    );
  }

  futureDate(starts_at) {
    return new Date(starts_at) > new Date();
  }

  bookingSlotState() {
    if (this.bookingSlot.state) {
      this.isConfirmBooking = false;
    }
  }
  setupDatesCorrectly() {
    this.bookingSlot.starts_at = !!this.bookingSlot.starts_at
      ? new Date(this.bookingSlot.starts_at)
      : new Date();
    if (this.data.selection_type) {
      if (this.data.selection_type == "single_slot") {
        this.bookingSlot.ends_at = moment(this.bookingSlot.starts_at)
          .add(this.doctor.average_booking_duration, "minutes")
          .toDate();
      }
    } else {
      this.bookingSlot.ends_at = !!this.bookingSlot.ends_at
        ? new Date(this.bookingSlot.ends_at)
        : moment(this.bookingSlot.starts_at)
            .add(this.doctor.average_booking_duration, "minutes")
            .toDate();
    }
  }
  checkIfEditable() {
    if (this.bookingSlot.starts_at < new Date()) {
      this.isEditable = false;
    }
  }

  onPhoneNumberChange() {
    this.validPhoneNumber =
      this.bookingSlotForm.controls.user_phone_number.status === "VALID";
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
