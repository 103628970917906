import {
  Component,
  ViewChild,
  OnInit,
  Input,
  ViewEncapsulation,
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { BookingSlotsService } from "src/app/services/booking-slots.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { BookingSlot } from "src/app/models/booking-slot";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { SlotType } from "src/app/enums/SlotType.enum";
import { BookingHelper } from "src/app/helpers/booking_helper";
import { BookingType } from "src/app/enums/bookingType.enum";
import { StateType } from "src/app/enums/stateType.enum";
import { MatDialog } from "@angular/material/dialog";
import { BookingDialogComponent } from "../booking-dialog/booking-dialog.component";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", visibility: "hidden" })
      ),
      state("expanded", style({ height: "*", visibility: "visible" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class TableComponent implements OnInit {
  displayedColumns = ["schedule", "type", "name", "actions"];
  dataSource;
  bookingData: BookingSlot[] = [];

  isIconToggled: boolean = false;
  rowId: string;

  isExpansionDetailRow = (i: number, row: Object) =>
    row.hasOwnProperty("detailRow");
  expandedElement: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  listParams = {
    pageNumber: 1,
  };
  _alldata: any[];
  _newData: any[];
  appointmentTypes = SlotType;
  bookingTypes = BookingType;
  appointmentStates = StateType;

  constructor(
    private dialog: MatDialog,
    private snackbar: SnackBarService,
    private bookingSlotsService: BookingSlotsService,
    private bookingHelper: BookingHelper,
    private errorHandler: ErrorsHandler,
    private translate: TranslateService
  ) {}

  getDoctorBookingSlots() {
    this.bookingSlotsService.toConfirm().subscribe(
      (data: BookingSlot[]) => {
        this._alldata = this.addGroups(data);
        this.dataSource = new MatTableDataSource(this._alldata);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.bookingData.push(...data);
      },
      (error) => this.snackbar.open(error.message)
    );
  }

  openBookingModal(data = {}) {
    this.dialog
      .open(BookingDialogComponent, {
        width: "515px",
        data: data,
        panelClass: "calendar-booking",
      })
      .afterClosed()
      .subscribe(() => {
        this.getDoctorBookingSlots();
      });
  }

  addGroups(data: any[]): any[] {
    var _newData = [];
    var groupname = "";
    data.map((row, ii) => {
      let starts_at = row.starts_at.split("T")[0];
      if (groupname != starts_at) {
        _newData.push({ starts_at: starts_at, isGroup: true });
        groupname = starts_at;
      }
      _newData.push(row);
    });
    return _newData;
  }

  isGroup(index, item): boolean {
    return item.isGroup;
  }

  confirmBookingSlot(bookingSlot) {
    const text = this.bookingHelper.getCancelMessage(bookingSlot);
    Swal.fire({
      title: this.translate.instant("connect.globals.confirmation_box_title"),
      text: text,
      type: this.translate.instant("connect.globals.confirmation_box_type"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#808285",
      confirmButtonText: this.translate.instant(
        "connect.globals.booking_confirm"
      ),
      cancelButtonText: this.translate.instant("connect.globals.cancel"),
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          this.translate.instant(
            "connect.appointment_table.booking_slot_confirmed"
          )
        );
        return this.bookingSlotsService.confirm(bookingSlot.id).subscribe(
          (data) => {
            this.getDoctorBookingSlots();
          },
          (error) =>
            this.errorHandler.handleError(
              this.translate.instant("connect.globals.error_occured_message")
            )
        );
      }
    });
  }

  cancelBookingSlot(bookingSlot) {
    const text = this.bookingHelper.getCancelMessage(bookingSlot);
    Swal.fire({
      title: this.translate.instant("connect.globals.confirmation_box_title"),
      text: text,
      type: this.translate.instant("connect.globals.confirmation_box_type"),
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808285",
      confirmButtonText: this.translate.instant(
        "connect.globals.cancel_booking"
      ),
      cancelButtonText: this.translate.instant("connect.globals.cancel"),
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          this.translate.instant(
            "connect.appointment_table.booking_slot_cancelled"
          )
        );

        return this.bookingSlotsService.cancel(bookingSlot.id).subscribe(
          (data) => {
            this.getDoctorBookingSlots();
          },
          (error) =>
            this.errorHandler.handleError(
              this.translate.instant("connect.globals.error_occured_message")
            )
        );
      }
    });
  }

  didntShowUp(bookingSlotId) {
    this.bookingSlotsService.didntShowUp(bookingSlotId, true).subscribe(
      (data) => this.snackbar.open("Signalez comme Ne est pas présenté"),
      (error) => this.errorHandler.handleError(error.message)
    );
  }

  toggleIcon(row) {
    this.rowId = row.id;
    return (this.isIconToggled = !this.isIconToggled);
  }

  ngOnInit() {
    this.getDoctorBookingSlots();
  }
}
