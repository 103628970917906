import {
  Component,
  ViewChild,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewEncapsulation,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { MedicalRecordService } from "src/app/services/medical-record.service";
import { SnackBarService } from "src/app/services/snack-bar.service";
import { Patient } from "src/app/models/medicalRecordPatient.model";
import { ErrorsHandler } from "src/app/services/error-handler.service";
import {
  trigger,
  transition,
  style,
  sequence,
  animate,
  state,
} from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { DoctorService } from "src/app/services/doctor.service";
import { PatientLabel } from "src/app/models/patient-label";
import { Doctor } from "src/app/models/doctor";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { environment as appConfig } from "../../../environments/environment";
import { GlobalService } from "src/app/services/global.service";
import { PlanType } from "src/app/enums/planType.enum";
import { PatientInformationDialogComponent } from "../../components/patient-information-dialog/patient-information-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-medical-record-table",
  templateUrl: "./medical-record-table.component.html",
  styleUrls: ["./medical-record-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
  animations: [
    trigger("rowsAnimation", [
      state("in", style({ opacity: 1 })),
      transition(":enter", [style({ opacity: 0 }), animate(600)]),
      transition(":leave", animate(600, style({ opacity: 0 }))),
    ]),
  ],
})
export class MedicalRecordTableComponent implements OnInit {
  displayedColumns: string[] = [
    "ref_id",
    "first_name",
    "gender",
    "phone_number",
    "actions",
  ];
  dataSource;
  searchValue: string = "";
  currentDoctor: Doctor;
  event: boolean = false;

  medical_record_form = new UntypedFormGroup({
    medical_record_from: new UntypedFormControl(""),
    medical_record_to: new UntypedFormControl(""),
    phone_number: new UntypedFormControl(""),
    from_age: new UntypedFormControl(""),
    to_age: new UntypedFormControl(""),
    first_name: new UntypedFormControl(""),
    last_name: new UntypedFormControl(""),
    ref_id: new UntypedFormControl('')
  });

  @Input() name: string;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  nextDisable: boolean = false;
  previousDisable: boolean = false;
  listParams = {
    pageNumber: 1,
  };
  patientLabels: PatientLabel[] = [];
  doctorPatientLabels: PatientLabel[] = [];
  constructor(
    public dialog: MatDialog,
    private medicalRecordService: MedicalRecordService,
    private router: Router,
    private translate: TranslateService,
    private doctorService: DoctorService,
    private snackBar: SnackBarService,
    private errorHandler: ErrorsHandler,
    private globalService: GlobalService,
    private cdref: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {}

  resetData() {
    this.medical_record_form.setValue({
      medical_record_from: "",
      medical_record_to: "",
      from_age: "",
      to_age: "",
      phone_number: "",
      last_name: "",
      first_name: "",
      ref_id:""
    });
    this.getMedicalRecords((this.listParams.pageNumber = 1));
  }

  filterBookings(page_index) {
    // Adapt writing to existing data
    let last_name = this.medical_record_form.value.last_name;
    last_name =
      last_name.charAt(0).toUpperCase() + last_name.substring(1).toLowerCase();
    let first_name = this.medical_record_form.value.first_name;
    first_name =
      first_name.charAt(0).toUpperCase() +
      first_name.substring(1).toLowerCase();
    // Creating the HTTP query
    if (
      this.medical_record_form.value.medical_record_from ||
      this.medical_record_form.value.medical_record_to ||
      this.medical_record_form.value.phone_number ||
      this.medical_record_form.value.from_age ||
      this.medical_record_form.value.ref_id ||
      this.medical_record_form.value.to_age ||
      this.medical_record_form.value.first_name ||
      this.medical_record_form.value.last_name
    ) {
      this.medical_record_form.value.medical_record_from =
        this.datePipe.transform(
          this.medical_record_form.value.medical_record_from,
          "yyyy-MM-dd"
        );
      this.medical_record_form.value.medical_record_to =
        this.datePipe.transform(
          this.medical_record_form.value.medical_record_to,
          "yyyy-MM-dd"
        );
      const filter =
        "page=" +
        page_index +
        "&start=" +
        this.medical_record_form.value.medical_record_from +
        "&end=" +
        this.medical_record_form.value.medical_record_to +
        "&phone_number=" +
        this.medical_record_form.value.phone_number +
        "&from_age=" +
        this.medical_record_form.value.from_age +
        "&to_age=" +
        this.medical_record_form.value.to_age +
        "&last_name=" +
        last_name +
        "&first_name=" +
        first_name +
        "&ref_id=" + this.medical_record_form.value.ref_id
      this.medicalRecordService.all(filter).subscribe(
        (data: Patient[]) => {
          this.filterRecords(data);
        },
        (error) =>
          this.errorHandler.handleError(
            this.translate.instant("connect.globals.error_occured_message")
          )
      );
    }
  }

  toggleBar() {
    this.event = !this.event;
  }

  getMedicalRecords(params) {
    this.listParams.pageNumber == 1
      ? (this.previousDisable = true)
      : (this.previousDisable = false);
    this.globalService.showSpinner();
    const filter = "page=" + params + "&name=" + this.searchValue;
    this.medicalRecordService.all(filter).subscribe(
      (data: Patient[]) => {
        this.globalService.hideSpinner();
        this.filterRecords(data);
      },
      (error) =>
        this.errorHandler.handleError(
          this.translate.instant("connect.globals.error_occured_message")
        )
    );
  }

  filterRecords(data: Patient[]) {
    data.length < 10 ? (this.nextDisable = true) : (this.nextDisable = false);
    data.filter((element) => {
      this.getPatientLabels(element);
    });
    this.dataSource = new MatTableDataSource(this.addGroups(data));
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = function (data, filter) {
      if (
        !filter ||
        ((data.first_name || data.last_name) &&
          (data.first_name + " " + data.last_name)
            .toLowerCase()
            .includes(filter)) ||
        (data.email && data.email.toLowerCase().includes(filter)) ||
        (data.phone_number && data.phone_number.toLowerCase().includes(filter))
      ) {
        return data;
      }
    };
  }

  ageFrom(dateOfBirth: any): number {
    return moment().diff(dateOfBirth, "years");
  }

  paginatorMedicalRecords(key: string) {
    key == "previous"
      ? this.listParams.pageNumber--
      : this.listParams.pageNumber++;
    this.getMedicalRecords(this.listParams.pageNumber);
  }

  ngOnInit() {
    this.getDoctorInfo();
    if (this.name) {
      this.searchValue = this.name;
    }
    this.getMedicalRecords((this.listParams.pageNumber = 1));
  }

  addGroups(data: Patient[]): Patient[] {
    let _newData = [];
    let groupname = "";
    data.map((row) => {
      let created_at = row.created_at.split("T")[0];
      if (groupname != created_at) {
        _newData.push({ created_at: created_at, isGroup: true });
        groupname = created_at;
      }
      _newData.push(row);
    });
    return _newData;
  }

  isGroup(index: number, item: Patient): boolean {
    return item.isGroup;
  }

  getDoctorInfo() {
    this.doctorService.currentDoctor$.subscribe((currentDoctor) => {
      this.currentDoctor = currentDoctor;
    });
  }

  getPatientLabels(patient) {
    if (patient && patient.patient_labels) {
      if (this.currentDoctor) {
        patient.patient_labels = this.currentDoctor.patient_labels.filter(
          ({ id }) => patient.patient_labels.includes(id)
        );
      }
    }
  }

  applyFilter(value: string) {
    this.searchValue = value.trim().toLowerCase();
    this.getMedicalRecords((this.listParams.pageNumber = 1));
  }

  deleteMedicalRecord(medicalRecordId) {
    Swal.fire({
      title: this.translate.instant("connect.globals.confirmation_box_title"),
      text: this.translate.instant("connect.globals.confirmation_box_text"),
      type: this.translate.instant("connect.globals.confirmation_box_type"),
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808285",
      confirmButtonText: this.translate.instant(
        "connect.globals.confirmation_box_delete_it"
      ),
      cancelButtonText: this.translate.instant("connect.globals.cancel"),
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          this.translate.instant("connect.medical_record.record_deleted")
        );
        return this.medicalRecordService
          .delete(medicalRecordId)
          .subscribe((deleteRecord) =>
            this.getMedicalRecords((this.listParams.pageNumber = 1))
          );
      }
    });
  }

  showMedicalRecodsDetails(MedicalRecordID: string) {
    // if (this.currentDoctor.plan_type == PlanType.CONNECT) {
    //   if (appConfig.is_doctor) {
    //     return this.router.navigate(["/medical-records", MedicalRecordID])
    //   }
    // } else {
    //   Swal.fire(
    //     this.translate.instant('connect.medical_record.only_for_connect')
    //   )
    // }
    if (appConfig.is_doctor) {
      return this.router.navigate(["/medical-records", MedicalRecordID]);
    }
  }

  showGenderInformation(patient) {
    let dialogRef = this.dialog.open(PatientInformationDialogComponent, {
      width: "800px",
      height: "auto",
      data: patient,
    });
    return dialogRef.afterClosed();
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }
}
